import React, { useEffect, useRef } from 'react';
import '../styles/pages/retailplatforms.css';
import { data } from '../json/retail';
import { BG, BtDot, RSC, mPOS, retailVideo, smartstore } from '../assets';

import { useNavigate, useParams } from 'react-router-dom';

const RetailPlatforms = () => {
  const datacard = [
    {
      heading: 'LiDO Self Checkout',
      paragrap:
        'Suntech Global offers LiDO self-service kiosk and LiDO mobile self-service an “RFID Enabled Self-Checkout” Application”, that identifies a quick and easy check-out with a higher reliability on the products in the customer bag. As a result, the payment process is accelerated, reducing queues, which improves the customer shopping experience and increases sales.',
      image: RSC,
    },
    {
      heading: 'LiDO mPOS',
      paragrap:
        'MPOS application improves customer satisfaction by engaging customer throughout its shopping journey by providing instore assistance and a quick check-out. Also it helps in reducing the hardware cost and retail space in the store thus increasing per store sale.',
      image: mPOS,
    },
    {
      heading: 'LiDO Smart Store',
      paragrap:
        'LiDO smart store application aims to improve Instore experience of the customers. The functionalities are designed to assist instore sales associates to resolve all the customer queries quickly & efficiently resulting in an enhanced customer experience. The application also assists sale associates in providing cost-effective services.',
      image: smartstore,
    },
  ];
  let currentIndex = 0;

  const moveLeftRigth = (move) => {
    if (move === 'left') {
      currentIndex = currentIndex > 0 ? currentIndex - 1 : datacard.length - 1;
    } else if (move === 'right') {
      currentIndex = currentIndex < datacard.length - 1 ? currentIndex + 1 : 0;
    }
    const currentData = datacard[currentIndex];
    document.getElementById('heading').textContent = currentData.heading;
    document.getElementById('paragraph').textContent = currentData.paragrap;
    document.getElementById('image').src = currentData.image;
  };
  const navigation = useNavigate();
  const MoveToTop = () => {
    navigation('#retailTop');
  };
  const { id } = useParams();
  const targetselfRef = useRef(null);
  const targetsmartRef = useRef(null);
  const targetmposRef = useRef(null);
  useEffect(() => {
    if (id === 'lidosmartstore') {
      if (targetsmartRef.current) {
        targetsmartRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (id === 'lidompos') {
      if (targetmposRef.current) {
        targetmposRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (id === 'lidoselfchekout') {
      if (targetselfRef.current) {
        targetselfRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <>
      <div div id="retailTop">
        {MoveToTop}

        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 retail_video_animation">
                <video
                  className="retail_video_animation_size"
                  autoPlay
                  loop
                  muted
                  playsinline
                >
                  <source src={retailVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-3 mb-5 ">
            <div className="row Retail_Main">
              <div className="col-lg-3 col-md-4 col-xs-4 Retail_Image ">
                <label htmlFor="">AI POWERED</label>
                <strong>LiDO</strong>

                <img src={smartstore} alt="" />
              </div>
              <div className="col-lg-3 col-md-4 col-xs-4 Retail_Image ">
                <strong>LiDO</strong>
                <img src={RSC} alt="" />
              </div>
              <div className="col-lg-3 col-md-4 col-xs-4 Retail_Image ">
                <strong>LiDO</strong>
                <img src={mPOS} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid main-retail-Lido">
            <div
              className="row retail_Lido_Main mb-5"
              ref={targetsmartRef}
              id="lidosmartstore"
            >
              <div className="col-lg-4 col-md-3 col-xs-6 Retail_Lido_Image">
                <strong>Lido</strong>
                <img src={smartstore} alt="" />
              </div>
              <div className="col-lg-8 col-md-9 col-xs-6 Retail_Lido_Description">
                <strong className="retal_card_Heading">LiDO Smart Store</strong>
                <span className="retail_card_description">
                  LiDO smart store application aims to improve Instore
                  experience of the customers. The functionalities are designed
                  to assist instore sales associates to resolve all the customer
                  queries quickly & efficiently resulting in an enhanced
                  customer experience. The application also assists sale
                  associates in providing cost-effective services.
                </span>
                <div className="about-us-bg-png">
                  <img src={BtDot} alt="" />
                </div>
                <div className="retail-page-bg-png">
                  <img src={BG} alt="" />
                </div>
              </div>
            </div>
            <div
              className="row retail_Lido_Main mb-5"
              ref={targetselfRef}
              id="lidoselfchekout"
            >
              <div className="col-lg-4 col-md-3  col-xs-6 Retail_Lido_Image">
                <strong>Lido</strong>
                <img src={RSC} alt="" />
              </div>
              <div className="col-lg-8 col-md-9  col-xs-6 Retail_Lido_Description1">
                <strong className="retal_card_Heading1">
                  LiDO Self Checkout
                </strong>
                <span className="retail_card_description1">
                  Suntech Global offers LiDO self-service kiosk and LiDO mobile
                  self-service an “RFID Enabled Self-Checkout” Application”,
                  that identifies a quick and easy check-out with a higher
                  reliability on the products in the customer bag. As a result,
                  the payment process is accelerated, reducing queues, which
                  improves the customer shopping experience and increases sales.
                </span>
                <div className="about-us-bg-png">
                  <img src={BtDot} alt="" />
                </div>
                <div className="retail-page-bg-png">
                  <img src={BG} alt="" />
                </div>
              </div>
            </div>
            <div
              className="row retail_Lido_Main mb-5"
              ref={targetmposRef}
              id="lidompos"
            >
              <div className="col-lg-4 col-md-3  col-xs-6 Retail_Lido_Image">
                <strong>Lido</strong>
                <img src={mPOS} alt="" />
              </div>
              <div className="col-lg-8 col-md-9  col-xs-6 Retail_Lido_Description2">
                <strong className="retal_card_Heading">LiDO mPOS</strong>
                <span className="retail_card_description">
                  MPOS application improves customer satisfaction by engaging
                  customer throughout its shopping journey by providing instore
                  assistance and a quick check-out. Also it helps in reducing
                  the hardware cost and retail space in the store thus
                  increasing per store sale.
                </span>
                <div className="about-us-bg-png">
                  <img src={BtDot} alt="" />
                </div>
                <div className="retail-page-bg-png">
                  <img src={BG} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 retail_text">
                <p>
                  <span>Envisioning </span> Next Gen Platforms
                </p>
              </div>
            </div>

            <div className="row mb-5 card_section retail_card_section">
              {data.map((item) => {
                return (
                  <div className="col-lg-4 mt-5 retail__card_parent">
                    <div class="retail__card">
                      <div className="retail__card_image_main">
                        {/* //reatil__card_img */}
                        <img className="" src={item.image} alt="Icon" />
                      </div>
                      <div class="retail__card_body">
                        <p class="retail__card_title mt-3">{item.tittle}</p>
                        <p class="retail__card_text">{item.content}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default RetailPlatforms;
