import React from 'react'
import '../styles/components/smallcards.css'
import { Link } from 'react-router-dom'

const SmallCards = ({image1,tittle1,paragraph1,link1,image2,tittle2,paragraph2,link2}) => {
  const goToTop=()=>{
    window.scrollTo(0,0);
  }
  return (
    <div className='container mt-5 mb-5 small_cards'>
      <div className="row small_cards_main">
        <div className="col-lg-12 small_cards_main_data_img">
        <img src={image1}alt="" />
        </div>
        <div className="col-lg-12 small_cards_main_data">
          <p className='small_card_tittle'>{tittle1}</p>
          <p className='small_card_paragraph'>
            {
              paragraph1
            }
          </p>
          <Link to={link1}><button className='mt-5 mb-2' onClick={goToTop}>Learn more</button></Link>
        </div>
      </div>
      <div className="row small_cards_main">
        <div className="col-lg-12 small_cards_main_data_img">
        <img src={image2}alt="" />
        </div>
        <div className="col-lg-12 small_cards_main_data">
          <p className='small_card_tittle'>{tittle2}</p>
          <p className='small_card_paragraph'>
            {
              paragraph2
            }
          </p>
           <Link to={link2}><button className='mt-5 mb-2'onClick={goToTop}>Learn more</button></Link>
        </div>
      </div>
    </div>
  )
}

export default SmallCards