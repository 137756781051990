import React from 'react'
import { workInProgress } from '../assets'
import "../styles/pages/workinprogress.css"
import Header from '../components/Header'
import Footer from '../components/Footer'

const Workinprogress = () => {
  return (
    <>
      <Header/>
    <div className='workin_main'>
      <img src={workInProgress} alt="work in progress" srcset="" /> 
    </div>
    <Footer/>
    </>
  )
}

export default Workinprogress
