import React, { useEffect, useState } from "react";
import { companyTower } from "../assets";
import "../styles/pages/odyssey.css";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import { img54 } from "../assets/suntechImages";

const Odyssey = () => {
  // const [months,setMonths] = useState("bydefault")
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("fdghjk");
    if (location.pathname === "/odyssey") {
      window.onscroll = function () {
        pageScroll();
      };
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  //Page Scroll Progress Bar
  function pageScroll() {
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    let scolledLength = Math.round(scrolled);
    if (scolledLength >= 5 && scolledLength <= 18) {
      document.getElementById("proBar")?.classList?.add("active");
      document.getElementById("proBar2")?.classList?.add("active");

      document.getElementById("2proBar")?.classList?.remove("active");
      document.getElementById("2proBar2")?.classList?.remove("active");

      document.getElementById("3proBar")?.classList?.remove("active");
      document.getElementById("3proBar3")?.classList?.remove("active");
    } else if (scolledLength >= 25 && scolledLength <= 46) {
      document.getElementById("2proBar")?.classList?.add("active");
      document.getElementById("2proBar2")?.classList?.add("active");

      document.getElementById("3proBar")?.classList?.remove("active");
      document.getElementById("3proBar3")?.classList?.remove("active");
    } else if (scolledLength >= 50 && scolledLength <= 70) {
      document.getElementById("3proBar")?.classList?.add("active");
      document.getElementById("3proBar3")?.classList?.add("active");
    } else {
      console.log("nothing");
    }
  }

  const clickToShowStory = (month) => {
    if (month === "2024") {
      window.scrollTo(0, 360);
    } else if (month === "2023") {
      window.scrollTo(0, 1100);
    } else if (month === "2022") {
      window.scrollTo(0, 2200);
    }
  };
  const MoveToTop = () => {
    navigation("#oddssyey_main_top");
  };

  const Linkedin = [
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7233767686323781632",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7159079068489965568",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7150831008550940673",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7146463046821896192",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7140959444347125763",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7140338036524965889",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7139927436758097920",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7137397629461688320",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7133401208504954881",
    },

    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7086602760011325442",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7044291921803886592",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7034116442899787777",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7041359405589671936",
    },

    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6898959502155354112",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6971066268724391938",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6988026218017099777",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6955406058727043072",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6982709368987267072",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6988752298550177792",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6991708876039438336",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7010923754935447552",
    },
    {
      url: "https://www.linkedin.com/embed/feed/update/urn:li:share:6880095232554274816",
    },
  ];
  return (
    <div id="oddssyey_main_top">
      {MoveToTop}
      <Banner
        image={img54}
        tittle={"Suntech Odyssey"}
        // suntechtagline={"Inspiring Customers Worldwide through Digital Realization"}
        // navbartittle={"About Us"}
      />
      {/* <Servicebanner img={companyTower} data={"Suntech Odyssey"}/> */}
      <section id="gototop">
        <div className="container progress_main_bar mt-5 mb-5" id="">
          <div className="row  text-center justify-content-around align-items-center">
            <div className="col-lg-12 timeline">
              <div className="dot" id="proBar"></div>
              <div className="pipe" id="proBar2">
                <p onClick={() => clickToShowStory("2024")}>2024</p>
              </div>
              <div className="dot" id="2proBar"></div>
              <div className="pipe" id="2proBar2">
                <p onClick={() => clickToShowStory("2023")}>2023</p>
              </div>
              <div className="dot" id="3proBar"></div>
              <div className="pipe" id="3proBar3">
                <p onClick={() => clickToShowStory("2022")}>2022</p>
              </div>
              <div className="dot"></div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid new_section_post">
          <div className="row" style={{ display: "contents" }}>
            {Linkedin.map((item) => {
              return (
                <div className="col-xl-4 new_post">
                  <iframe className="post_url" src={item.url}></iframe>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Odyssey;
