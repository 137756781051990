import React from "react";
import "../styles/pages/aliances.css";
import Banner from "../components/Banner";
import {
  Alliancesbanner,
  Card1,
  Card2,
  agon,
  aliancebanner,
  keonn,
  mission1,
  mission22,
  oracle,
  suntechcard,
} from "../assets";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SmallCards from "../components/SmallCards";
import Breadcrump from "../components/Breadcrump";
import { useNavigate } from "react-router-dom";

const Aliances = () => {
  const navigation = useNavigate();
  const MoveToTop = () => {
    navigation("#aliance_top");
  };
  return (
    <div id='aliance_top'>
      {MoveToTop}
      <Banner
        image={Alliancesbanner}
        tittle={"Our Alliances"}
        suntechtagline={"Empowering Innovation, Together"}
        navbartittle={"Alliances"}
      />
      <Breadcrump page={"Alliances"} />

      <div className='container mt-3 mb-5 '>
        <div className='row Alliances_Main'>
          {/* <div className='textmarquee'>
            <marquee>
              The logos are respective trademarks of the companies shown below
              and serve as a testament to the quality of services Suntech Global
              provides and the valued collaborations.
            </marquee>
          </div> */}
          <div className='col-lg-3 col-md-4 col-xs-4 Alliances_Image'>
            <img src={oracle} alt='' />
          </div>
          <div className='col-lg-3 col-md-4 col-xs-4 Alliances_Image'>
            <img src={keonn} alt='' />
          </div>
          <div className='col-lg-3 col-md-4 col-xs-4 Alliances_Image'>
            <img src={agon} alt='' />
          </div>
        </div>
      </div>
      {/* <SmallCards image1={Card1}tittle1={"Vision"}paragraph1={"The Company aims to provide innovative and cost effective Business solutions to the retail and Consumer-Tech industry."} link1={"/vision"}
      image2={mission22}tittle2={"Culture & Values"}paragraph2={"Our unyielding dedication to our customers, partners, and employees is reflected in the core values that underpin everything we do at Suntech"}link2={"/culturevalue"}/> */}
    </div>
  );
};

export default Aliances;
