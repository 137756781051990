/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { GrNext, GrPrevious } from "react-icons/gr";
import {
  lidoselfcheckout,
  lidosmartstore,
  mpos,
  xstore,
  computertech,
  whoweare,
  fashion,
  grocery,
  consumertech,
  homefernish,
  futurecheckout,
  retailSelfchekoutdmo,
  agon,
  keon,
  timesinternet,
  rfidlanding,
  tagrelease,
  tegplace,
  extralogo,
  rightArrow,
  tagRelease,
  tagPlace,
  oracaleservice,
  cLogo,
  previousbutton,
  nextbutton,
  Unicomer,
  OracleXstoreGo,
} from "../assets/index.js";

import "../styles/pages/landing.css";
import { Link } from "react-router-dom";

import Odometer from "odometer";

function Landing() {
  const [hide, setHide] = useState(false);

  const [page, setPage] = useState(1);

  const vidRef = useRef(null);
  const vidRef2 = useRef(null);
  const vidRef3 = useRef(null);
  const landingVideo = useRef(null);
  const doc = useRef(null);

  landingVideo.current && console.log("ss", landingVideo);

  const handlePlayVideo = () => {
    vidRef.current.play();
    setHide(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const targetSection = document.getElementById("your-section-id");
      if (targetSection) {
        const targetOffsetTop = targetSection.offsetTop;
        if (scrollPosition >= targetOffsetTop - window.innerHeight) {
          // Call your function when scrolled down to the target section
          console.log("Scrolled down to target section");

          // Remove the scroll listener to avoid repetitive calls
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const createOdometer = (el, value) => {
      const odometer = new Odometer({
        el: el,
        value: 0,
      });

      let hasRun = false;

      const options = {
        threshold: [0, 0.9],
      };

      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!hasRun) {
              odometer.update(value);
              hasRun = true;
            }
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);
      observer.observe(el);
    };

    const subscribersOdometer = document.querySelector(".first");
    createOdometer(subscribersOdometer, 10);

    const videosOdometer = document.querySelector(".second");
    createOdometer(videosOdometer, 25);

    const projectsOdometer = document.querySelector(".third");
    createOdometer(projectsOdometer, 20);

    const projectsOdometer2 = document.querySelector(".fourth");
    createOdometer(projectsOdometer2, 30);

    const projectsOdometer3 = document.querySelector(".fifth");
    createOdometer(projectsOdometer3, 10);

    // Cleanup function
    return () => {
      // Cleanup IntersectionObserver here if needed
    };
  }, []);

  useEffect(() => {
    const shopvideo = document.getElementById("shoppingvid");

    if (page === 3 && shopvideo) {
      shopvideo.load();

      const handleVideoEnded = () => {
        setPage(1); // Reset to the first page after video ends
      };

      shopvideo.addEventListener("ended", handleVideoEnded);

      // Clean up the event listener when the component unmounts or page changes
      return () => {
        shopvideo.removeEventListener("ended", handleVideoEnded);
      };
    }
  }, [page]);

  const handleNext = () => {
    setPage((prevPage) => (prevPage < 3 ? prevPage + 1 : 1)); // Loop back to the first page after the last page
  };

  const handlePrevious = () => {
    setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 3)); // Loop back to the last page from the first page
  };

  return (
    <>
      <div className="Main">
        <div className="TopHeadMain">
          <div
            className={
              page === 1
                ? "videoContainerShow dismiss"
                : "videoContainerHide selected"
            }
          >
            {/* Hero Section */}
            <section className="mainDiv">
              <div className="main_heading">
                <span>Retail</span>
              </div>

              <div className="main">
                <p className="product_heading_rotate">PRODUCT</p>

                <div className="Product_card_main">
                  <Link className="cardclick" to="/retail/lidoselfchekout">
                    <div className="card1">
                      <img
                        className="selfchekout_image_lido"
                        src={lidoselfcheckout}
                        alt="LiDO Self Checkout"
                      />
                      <span className="product_text">
                        LiDO Self <br /> Checkout
                      </span>
                    </div>
                  </Link>
                  <Link className="cardclick" to="/retail/lidosmartstore">
                    <div className="card1">
                      <img
                        className="selfchekout_image_smart"
                        src={lidosmartstore}
                        alt="LiDO Smart Store"
                      />
                      <span className="product_text">
                        LiDO Smart <br />
                        Store
                      </span>
                    </div>
                  </Link>
                  <Link className="cardclick" to="/retail/lidompos">
                    <div className="card1">
                      <img
                        className="selfchekout_image_mpos"
                        src={mpos}
                        alt="LiDO mPOS"
                      />
                      <span
                        className="product_text"
                        style={{ marginBottom: "5px" }}
                      >
                        LiDO mPOS
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              <br />
              <br />
              <div className="main">
                <p className="product_heading_rotate">SERVICES</p>

                <div className="Product_card_main">
                  <Link className="cardclick" to="/service/xstore">
                    <div className="card1">
                      <img
                        className="selfchekout_image_oracle"
                        src={oracaleservice}
                        alt="ORACLE Xstore"
                      />
                      <span className="product_text">
                        ORACLE
                        <br />
                        Xstore
                      </span>
                    </div>
                  </Link>
                  <Link className="cardclick" to="/service/orpos">
                    <div className="card1">
                      <img
                        className="selfchekout_image"
                        src={xstore}
                        alt="Retail orpos"
                      />
                      <span className="product_text">
                        Retail
                        <br />
                        orpos
                      </span>
                    </div>
                  </Link>
                  <Link className="cardclick" to="/service/consumer">
                    <div className="card1">
                      <img
                        className="selfchekout_image_consumer"
                        src={computertech}
                        alt="Consumer-Tech"
                      />
                      <span className="product_text">
                        Consumer-
                        <br /> Tech
                      </span>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="carousel-dots">
                {[1, 2, 3].map((_, index) => (
                  <span
                    key={index}
                    className={index + 1 === page ? "active" : ""}
                    onClick={() => setPage(index + 1)}
                  ></span>
                ))}
              </div>

              {/* Next Button */}
              <div id="chnagepage" className="Top_main_dispaly_chanege_next">
                <a id="movebutton" className="next" onClick={handleNext}>
                  <img className="nextbutton" src={nextbutton} alt="Next" />
                </a>
              </div>
            </section>
          </div>

          <div
            className={
              page === 2
                ? "videoContainerShow selected"
                : "videoContainerHide dismiss"
            }
          >
            <section className="mainDiv2">
              <img
                src={OracleXstoreGo}
                alt="OracleXstoreGo"
                className="background-image"
              />
              {/* Previous Button */}
              <div className="carousel-dots">
                {[1, 2, 3].map((_, index) => (
                  <span
                    key={index}
                    className={index + 1 === page ? "active" : ""}
                    onClick={() => setPage(index + 1)}
                  ></span>
                ))}
              </div>
              <div
                id="chnagepage"
                className="Top_main_dispaly_chanege_previous"
              >
                <a id="movebutton" className="prev" onClick={handlePrevious}>
                  <img
                    className="previousbutton"
                    src={previousbutton}
                    alt="Previous"
                  />
                </a>
                &nbsp; &nbsp;
              </div>

              {/* Next Button */}
              <div id="chnagepage" className="Top_main_dispaly_chanege_next">
                <a id="movebutton" className="next" onClick={handleNext}>
                  <img className="nextbutton" src={nextbutton} alt="Next" />
                </a>
              </div>
            </section>
          </div>

          <div
            id="video_card"
            className={
              page === 3
                ? "videoContainerShow selected"
                : "videoContainerHide dismiss"
            }
          >
            <video id="shoppingvid" autoPlay muted className="Shopingshowcase">
              <source src={rfidlanding} type="video/mp4" />
            </video>

            {/* Previous Button */}
            <div className="carousel-dots">
              {[1, 2, 3].map((_, index) => (
                <span
                  key={index}
                  className={index + 1 === page ? "active" : ""}
                  onClick={() => setPage(index + 1)}
                ></span>
              ))}
            </div>
            <div id="chnagepage" className="Top_main_dispaly_chanege_previous">
              <a id="movebutton" className="prev" onClick={handlePrevious}>
                <img
                  className="previousbutton"
                  src={previousbutton}
                  alt="Previous"
                />
              </a>
              &nbsp; &nbsp;
            </div>
          </div>

          {/* Dot Indicators */}
          <div className="carousel-dots">
            {[1, 2, 3].map((_, index) => (
              <span
                key={index}
                className={index + 1 === page ? "active" : ""}
                onClick={() => setPage(index + 1)}
              ></span>
            ))}
          </div>
        </div>

        <section className="Customer_service_main">
          <div className="customer_heading_main">
            <span className="customer_heading_text">
              Global <span className="customer_text">Customers</span> Serviced
            </span>
          </div>
          <div className="Customer_Number_Contain">
            <div className="first_Container">
              <div className="container_first firstContainer">
                <div>
                  <div className="num">01</div>
                </div>
                <div className="main_container">
                  <div className="all_text">
                    A Hypermarket
                    <br />
                    Retailer in India
                  </div>
                  <div className="dot">.</div>
                </div>
              </div>

              <div className="container_first">
                <div>
                  <div className="num">02</div>
                </div>
                <div className="main_container">
                  <div className="all_text">
                    A Large Middle
                    <br />
                    Eastern Fashion Retailer
                  </div>
                  <div className="dot">.</div>
                </div>
              </div>

              <div className="container_first">
                <div>
                  <div className="num">03</div>
                </div>
                <div className="main_container">
                  <div className="all_text">
                    A Leading Retail
                    <br />
                    Fashion Brand in India
                  </div>
                  <div className="dot">.</div>
                </div>
              </div>
            </div>
            <br />

            <div className="second_Container">
              <div className="container_first sec_cointain">
                <div>
                  <div className="num">04</div>
                </div>
                <div className="main_container">
                  <div className="all_text">
                    A Leading Consumer
                    <br />
                    Electronics Company in KSA
                  </div>
                  <div className="dot_four">.</div>
                </div>
              </div>

              <div className="container_first sec_cointain2">
                <div>
                  <div className="num">05</div>
                </div>
                <div className="main_container">
                  <div className="all_text">
                    A Leading Multinational
                    <br />
                    Retailer in Americas
                  </div>
                  <div className="dot">.</div>
                </div>
              </div>

              <div className="container_first sec_cointain3">
                <div>
                  <div className="num">06</div>
                </div>
                <div className="main_container">
                  <div className="all_text">
                    A Premium Digital
                    <br />
                    Products Company
                  </div>
                  <div className="dot">.</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Omini_Cheenal_Counter_Number" id="your-section-id">
          <div className="Omini_Main_container">
            <div className="Omini_Container">
              <span className="Number_container">
                <p id="first" className="first">
                  0
                </p>
                <span className="plush_sign">+</span>
              </span>
              <p className="Text_dec">
                Omni Channel <br /> Retail Platforms
              </p>
            </div>
            <div className="Omini_Container">
              <span className="Number_container">
                <span id="second" className="second">
                  0
                </span>
                <span className="plush_sign">+</span>
              </span>
              <p className="Text_dec">
                Enhancements for Fashion/
                <br />
                Electronics Retailers
              </p>
            </div>
            <div className="Omini_Container">
              <span className="Number_container">
                <p id="third" className="third">
                  0
                </p>
                <span className="plush_sign">+</span>
              </span>
              <p className="Text_dec">
                Enhancements for Home Improvement/
                <br />
                Grocery Retailers
              </p>
            </div>
            <div className="Omini_Container Omini_Container2">
              <span className="Number_container">
                <p id="fourth" className="fourth">
                  0
                </p>
                <span className="plush_sign">+</span>
              </span>
              <p className="Text_dec_Omini_Container2">
                Projects for Unique number of Customers
              </p>
            </div>
            <div className="Omini_Container Omini_Container">
              <span className="Number_container ">
                <p id="fifth" className="fifth ">
                  0
                </p>
                <span className="plush_sign">+</span>
              </span>
              <p className="Text_dec">
                Leading <br /> Customer
              </p>
            </div>
          </div>
        </section>

        <section className="Main_Who_weAre">
          <div className="Who_WeAre_Main_Container">
            <div className="Who_WeAre_Container">
              <div className="Who_WeAre_Image_container">
                <img className="Who_WeAre_Image" src={whoweare} alt="Left" />
              </div>
              <div className="Who_WeAre_Mian_textContainer">
                <span className="Who_WeAre_Heading_text">
                  Who <span className="Who_WeAre_Bold_text">We Are</span>
                </span>
                <p className="Who_WeAre_P">
                  <span className="Who_WeAre_suntech">Suntech Global</span>{" "}
                  utilizes the collective 100+ years of experience from our
                  Leadership team to deliver innovative solutions to Retail and
                  Consumer Tech industries. With a track record of successful
                  project delivery to prominent global clients like Landmark,
                  Lifestyle, SPAR, eXtra, and Times Internet across the USA,
                  Europe, the Middle East, and India, we offer unmatched
                  business and technology expertise.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="Disrupting_main">
          <div className="Disrupting_main_heading">
            <span className="Disrupting_main_heading_text">
              Disrupting <span className="Disrupting_text_retail">Retail</span>
            </span>
          </div>
        </section>

        <section className="Image_card">
          <div className="row">
            <div className="col-lg-6 col-sm-12 contain_image">
              <img src={fashion} alt="Avatar" className="image" />
              <div className="fashion_text">
                <span>FASHION</span>
              </div>
              <div className="overlay_fashion">
                <div className="text">
                  <span>FASHION</span>
                  <p className="landing_industry_content">
                    We have successfully implemented and managed leading retail
                    fashion brands in India and Middle East.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 contain_image">
              <img src={grocery} alt="Avatar" className="image" />
              <div className="grocery_text">
                <span>GROCERY</span>
              </div>
              <div className="overlay">
                <div className="text">
                  <span>GROCERY</span>
                  <p className="landing_industry_content">
                    We have successfully implemented and managed a large grocery
                    retailer.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12 contain_image">
              <img src={consumertech} alt="Avatar" className="image" />
              <div className="consumer_text">
                <span>CONSUMER TECH</span>
              </div>
              <div className="overlay_fashion">
                <div className="text">
                  <span>CONSUMER TECH</span>
                  <p className="landing_industry_content">
                    We have successfully implemented and managed a premium
                    digital product company.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 contain_image">
              <img src={homefernish} alt="Avatar" className="image" />
              <div className="furnishing_text">
                <span>HOME IMPROVEMENT</span>
              </div>
              <div className="overlay">
                <div className="text">
                  <span>HOME IMPROVEMENT</span>
                  <p className="landing_industry_content">
                    We have successfully implemented and managed a leading
                    consumer electronics company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Main_future">
          <div className="head_future">
            <h3>SELF - CHECKOUT</h3>
            <h1>
              Embrace the <br /> Future of Retail...
            </h1>
            <span className="mt-5 mb-5">
              Introducing the future of self-checkout. Futureproof, modular and
              constantly updated, <br />
              the Skip Self-Checkout Kiosk is modern and sleek.
            </span>
            <br />
            <Link to={"/contactus"}>
              <button type="button" className="future_GetDemo">
                GET DEMO
              </button>
            </Link>
          </div>

          <div className="Futurechekout">
            <img className="Futurechekoutimg" src={futurecheckout} alt="" />
          </div>
        </section>

        <section>
          <div className="Suport_main_container">
            <div className="Suport_container">
              <span className="Suport_top_number">100%</span>
              <span className="Suport_all_text">Vertically Integrated</span>
            </div>
            <div className="Suport_container">
              <span className="Suport_top_number">8X</span>
              <span className="Suport_all_text">
                More <br /> Affordable
              </span>
            </div>
            <div className="Suport_container">
              <span className="Suport_top_number">
                3 <br />
                Months
              </span>
              <span className="Suport_all_text">ROI</span>
            </div>
            <div className="Suport_container">
              <span className="Suport_top_number">24/7</span>
              <span className="Suport_all_text">Support</span>
            </div>
          </div>
        </section>

        <section className="Rfid_Demo_main">
          <div className="rfid_text">
            <h4 className="ml-5 fw-bold">A NEW GEN</h4>
            <h1 className="fw-bold">RFID SELF CHECKOUT</h1>
          </div>
          <div className="videoimg mt-4">
            <video
              className="rfiddemo_video"
              id="rfidemo_video"
              ref={vidRef}
              muted
              controls
              loop
            >
              <source src={retailSelfchekoutdmo} />
            </video>
            {hide ? (
              ""
            ) : (
              <span
                className="play_button_video_icon"
                onClick={() => handlePlayVideo()}
              >
                <FaPlay className="play_icon_leaderShip" />
              </span>
            )}
          </div>
        </section>

        <section className="Main_OurReatail_Rfid">
          <div className="Main_OurReatail_Rfid_heading">Our Retail RFID</div>
          <div className="OurReatail_Rfid_text">
            <div>
              <span className="OurReatail_Rfid_Leading">
                Leading Technologies
              </span>
            </div>
            <div className="OurReatail_Rfid_image_main">
              <img className="OurReatail_Rfid_image_agon" src={agon} alt="" />
              <div className="vertical"></div>
              <img className="OurReatail_Rfid_image" src={keon} alt="" />
            </div>
          </div>
        </section>

        <section className="Main_Tag_replacement">
          <div className="Tag_replacement">
            <div className="card" style={{ maxWidth: "33rem" }}>
              <video
                poster={tegplace}
                src={tagPlace}
                class="card-img-top"
                ref={vidRef2}
                controls
              ></video>

              <div className="card-body card_body_hover">
                <h5 className="card-title card_title_heading">Tag Placement</h5>
                <p className="card-text" style={{ fontFamily: "Poppins" }}>
                  Ensure the Concept Tags are completely effective by placing
                  them correctly in the recommended positions for t-shirts,
                  tops, blouses, jeans, and trousers.
                </p>
              </div>
            </div>
            <div className="card" style={{ maxWidth: "33rem" }}>
              <video
                poster={tagrelease}
                src={tagRelease}
                class="card-img-top"
                ref={vidRef3}
                controls
              ></video>
              <div className="card-body card_body_hover">
                <h5 className="card-title card_title_heading">Tag Release</h5>
                <p className="card-text" style={{ fontFamily: "Poppins" }}>
                  The Concept Tag has a locking mechanism that is removed in a
                  totally unique way. Make sure you are using the releaser
                  correctly to avoid any problems.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="slider_main">
          <div className="SlideHeading">
            <h1>Accolades & Achievements</h1>
          </div>

          <div className="containor_slide">
            <div
              id="carouselExampleControls"
              class="carousel slide "
              data-bs-ride="false"
            >
              <div class="carousel-inner">
                <div class="carousel-item active mt-5">
                  <div className="slide_card">
                    <div className="card text-center">
                      <div className="card-body text_align">
                        <div className="carousel_heading_main">
                          <div className="firstname">
                            <h4 className="clint-title">
                              Jose Gabriel Chariano
                            </h4>
                            <h6 className="clint-designation">
                              Head of IT Retail
                            </h6>
                          </div>
                          <div className="secondImage">
                            <img
                              className="carousel_heading_image_unicomer"
                              src={Unicomer}
                              alt=""
                            />
                          </div>
                          <div className="startimag">
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>
                          </div>
                        </div>
                        <p className="card-text card_text_paragraph">
                          “The implementation of Oracle Xstore has been a
                          journey full of discoveries, and we couldn't have
                          achieved it without Suntech team's commitment and
                          dedication. The coordination with the corporate teams,
                          regional teams in Nicaragua, and Suntech team's
                          efforts were crucial to our shared success. Suntech
                          team's effort to go above and beyond was key to making
                          this happen. The digital transformation of Unicomer is
                          now a reality in Nicaragua, and the positive results
                          are motivating us to go even further. We look forward
                          to continuing to build great things together!”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="carousel-item  mt-5">
                  <div className="slide_card">
                    <div className="card text-center ">
                      <div className="card-body text_align">
                        <div className="carousel_heading_main">
                          <div className="firstname">
                            <h4 className="clint-title">SANJAY GOYAL</h4>
                            <h6 className="clint-designation">
                              Vice - President
                            </h6>
                          </div>
                          <div className="secondImage">
                            <img
                              className="carousel_heading_image"
                              src={timesinternet}
                              alt=""
                            />
                          </div>
                          <div className="startimag">
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>
                          </div>
                        </div>
                        <p className="card-text card_text_paragraph">
                          “Suntech Global has been our trusted IT partner. The
                          founders are passionate, committed and firmly believe
                          the customer is king. We are impressed with their
                          strategic vision of products, platforms and services
                          focused on the retail, consumer tech and healthcare
                          verticals. Suntech Global team offers deep
                          domain/technical expertise and at the same time is
                          highly responsive and committed to our success”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="carousel-item mt-5">
                  <div className="slide_card">
                    <div className="card text-center">
                      <div className="card-body text_align">
                        <div className="carousel_heading_main">
                          <div className="firstname">
                            <h4 className="clint-title">SHAHNAWAZ AHMAD</h4>
                            <h6 className="clint-designation">
                              Customer Experience Manager
                            </h6>
                          </div>
                          <div className="secondImage">
                            <img
                              className="carousel_heading_image_extra"
                              src={extralogo}
                              alt=""
                            />
                          </div>
                          <div className="startimag">
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>
                          </div>
                        </div>
                        <p className="card-text card_text_paragraph">
                          “Finding an IT solutions provider that understands the
                          specific needs of the retail industry was challenging
                          until we came across Suntech Global. Their team's
                          expertise and experience in working with retail
                          businesses gave us confidence in their ability to
                          deliver. They have provided us with comprehensive
                          support and innovative solutions, helping us overcome
                          various technological hurdles. The team at Suntech
                          Global has been attentive, proactive, and reliable in
                          their service delivery. We are extremely satisfied
                          with the solutions they have implemented for us, and
                          we highly recommend their services to any retail
                          company seeking top-notch IT support..”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item mt-5">
                  <div className="slide_card">
                    <div className="card text-center">
                      <div className="card-body text_align">
                        <div className="carousel_heading_main">
                          <div className="firstname">
                            <h4 className="clint-title">ABHISHEK SINGH</h4>
                            <h6 className="clint-designation">
                              Senior Lead - Retail
                            </h6>
                          </div>
                          <div className="secondImage">
                            <img
                              className="carousel_heading_image_suntech"
                              src={cLogo}
                              alt=""
                            />
                          </div>
                          <div className="startimag">
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>&nbsp;
                            <span class="fa fa-star checked"></span>
                          </div>
                        </div>
                        <p className="card-text card_text_paragraph">
                          “From the very first day, I was warmly welcomed into a
                          team of talented and supportive individuals who made
                          me feel like a valuable member of the organization.
                          The inclusive and collaborative culture at Suntech
                          fostered an environment where innovative ideas were
                          encouraged, and everyone's contributions were
                          acknowledged. This support has been invaluable in
                          helping me maintain a positive and motivated outlook,
                          which in turn has enhanced my productivity.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev left_button mt-5 prev_button"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="prev"
            >
              <span className="arrowLeftLending">&#8593;</span>
              <span class="sr-only">Previous</span>
            </button>
            <button
              class="carousel-control-next right_button mt-5 next_button"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="next"
            >
              <span className="arrowRightLending">&#8595;</span>
              <span class="sr-only">Next</span>
            </button>
          </div>
        </section>

        <section className="lets_talk_main">
          <div className="lets_talk_heading mt-3">
            <h1>Ready for the future of retail?</h1>
          </div>
          <div className="lest_paragraph_text">
            <h6>You don’t have to wait. Contact our team, see it in action</h6>
          </div>
          <div className="lets_button">
            <Link to={"/contactus"}>
              <button
                type="button"
                className="btn btn-light btn-lg lets_buttoninside"
              >
                Let’s talk
              </button>
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}

export default Landing;
