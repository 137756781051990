import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import {
  Manoj_RAI,
  Manoj_RAIPoster,
  Mposter,
  companyTower,
  left,
  pramodKpoor,
  right,
} from "../assets";
import Breadcrump from "../components/Breadcrump";

import "../styles/pages/technicaldesk.css";
import { img54 } from "../assets/suntechImages";

function TechnicalDesk() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigation = useNavigate();
  const vidRef = useRef(null);

  const MoveToTop = () => {
    navigation("#technicaldesk");
  };

  const technicalPersonData = [
    {
      img: pramodKpoor,
      name: "Pramod Kpoor",
      designation: "President Global Delivery",
      heading: "Technology Enabler for customer management in retail",
      descripction:
        "The retail industry is continually evolving, driven by technology and the need to enhance customer experiences while combating issues like shoplifting and inefficiencies at checkout. High retail shrinkage rates in India emphasize the need for innovative solutions. Suntech Global offers LiDO, an RFID-enabled self-service kiosk and mobile application,designed to streamline the checkout process, reduce wait times, and enhance customer satisfaction. Their system uses RFID tags to expedite payment, minimize queues, and ultimately improve the overall shopping experience for customers.",
      url: "https://drive.google.com/file/d/1COJDdFRZn3d6mxUc-7Wppr1Dkb4ky2fq/view",
    },
    {
      img: pramodKpoor,
      name: "Pramod Kpoor",
      designation: "President Global Delivery",
      heading: "Innovation through Ideation Lab",
      descripction: `Suntech Global proposes implementing a Retail Ideation Lab, an innovative approach to client engagement aimed at achieving a 'DO IT RIGHT, FIRST TIME' delivery model. This lab fosters collaboration among client teams, business stakeholders, and technical experts to generate creative solutions aligned with business objectives. By providing a dedicated space for brainstorming and exploration, the lab ensures full participation and commitment from all parties involved. Through this approach, Suntech Global aims to deliver IT systems that meet business needs effectively and efficiently, enhancing overall project success.`,
      url: "https://drive.google.com/file/d/1WMBog6vMf6Y7-hoxJdtf2r4Cc7DLOfH6/view",
    },
    {
      img: Manoj_RAI,
      name: "Manoj Mittal",
      designation: "Cofounder & CEO",
      heading: "",
      descripction: `Thrilled to share a powerful moment from RISE Expo & Summit 2023, where our CEO Manoj Mittal delivered an inspiring talk. 
      Witness the future of innovation and leadership as we continue to elevate and shape the industry landscape.`,
      url: "",
    },
  ];

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? technicalPersonData.length - 1 : prevIndex - 1
    );
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === technicalPersonData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentPerson = technicalPersonData[currentIndex];
  let currNumber = currentIndex + 1;

  return (
    <div id='technicaldesk'>
      {MoveToTop}
      <Banner image={img54} tittle={"Our Technical Desk"} />
      <Breadcrump page={"Technical Desk"} />

      <section>
        <div className='container Technical_container mb-5'>
          <div className='row Technical_main'>
            {currNumber === 3 ? (
              <div className='col-lg-7 col-md-6 col-xs-6 Technical_left'>
                <video
                  poster={Manoj_RAIPoster}
                  ref={vidRef}
                  className=''
                  id='manojvideoRAI'
                  controls
                  frameborder='0'
                >
                  <source src={currentPerson.img} />
                </video>
                <div className='Technical_buttom'>
                  {/* <span>{currentPerson.name}</span> */}
                  {/* <span>{currentPerson.designation}</span> */}
                </div>
              </div>
            ) : (
              <div className='col-lg-5 col-md-6 col-xs-6 Technical_left'>
                <img src={currentPerson.img} alt='' />
                <div className='Technical_buttom'>
                  <span>{currentPerson.name}</span>
                  <span>{currentPerson.designation}</span>
                </div>
              </div>
            )}
            {currNumber === 3 ? (
              <div div className={"col-lg-5 col-md-6 col-xs-6 Technical_right"}>
                <p>{currentPerson.descripction} </p>
                <div className='dots_container'>
                  {technicalPersonData.map((item, index) => {
                    return (
                      <div
                        className={
                          index + 1 === currNumber ? "dots" : "dotsPre"
                        }
                      >
                        {" "}
                      </div>
                    );
                  })}
                </div>
                <div className='arrow_container'>
                  <img
                    src={left}
                    alt=''
                    className={
                      currNumber === 2
                        ? "arrow_left_image"
                        : "arrow_left_image1"
                    }
                    onClick={handleLeftClick}
                  />
                  <img
                    src={right}
                    alt=''
                    className={
                      currNumber === 2
                        ? "arrow_right_image"
                        : "arrow_right_image1"
                    }
                    onClick={handleRightClick}
                  />
                </div>
              </div>
            ) : (
              <div
                className={
                  currNumber === 1
                    ? "col-lg-7 col-md-6 col-xs-6 Technical_right"
                    : "col-lg-7 col-md-6 col-xs-6 Technical_right2"
                }
              >
                <div
                  className={
                    currNumber === 1
                      ? "Technical_main_data_section"
                      : "Technical_main_data_section2"
                  }
                >
                  <strong>
                    {currNumber}. {currentPerson.heading}
                  </strong>
                  <p>
                    {currentPerson.descripction}{" "}
                    <span className='ViewFullArticle'>
                      <a
                        href={currentPerson.url}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {" "}
                        View Full Article.
                      </a>
                    </span>{" "}
                  </p>
                </div>
                <div className='dots_container'>
                  {technicalPersonData.map((item, index) => {
                    return (
                      <div
                        className={
                          index + 1 === currNumber ? "dots" : "dotsPre"
                        }
                      >
                        {" "}
                      </div>
                    );
                  })}
                </div>
                <div className='arrow_container'>
                  <img
                    src={left}
                    alt=''
                    className={
                      currNumber === 2
                        ? "arrow_left_image"
                        : "arrow_left_image1"
                    }
                    onClick={handleLeftClick}
                  />
                  <img
                    src={right}
                    alt=''
                    className={
                      currNumber === 2
                        ? "arrow_right_image"
                        : "arrow_right_image1"
                    }
                    onClick={handleRightClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default TechnicalDesk;
