import {
  Retail1,
  Retail2,
  Retail3,
  Retail4,
  Retail5,
  Retail6,
  Retail7,
  Retail8,
} from "../assets/index";
export const data = [
  {
    id: 1,
    image: Retail1,
    tittle: "E-WALLET/BASKET",
    content:
      "Suntech has a unique concept framework to build your digital wallet and integrate a POS system with 3rd party digital wallet.",
  },
  {
    id: 2,
    image: Retail2,
    tittle: "COUPON AS A SERVICE",
    content:
      "Our unique solution helps retailers to bring their unique business model related to coupon generation/redemption and use it as gift or loyalty rewards which can be enabled in our coupon service on the SAAS model.",
  },
  {
    id: 3,
    image: Retail3,
    tittle: "APPOINTMENT AS A SERVICE",
    content:
      "Some stores work on personalised service where making appointments are important. Our framework provides a user-friendly appointment management system as a service so that more customers can approach stores and also enhance the customer experience.",
  },
  {
    id: 4,
    image: Retail4,
    tittle: "INVENTORY AS A SERVICE",
    content:
      "Inventory is crucial information/data which enables the store to carry out selling business. Stores are normally bound to one single status inventory system which restricts the business opportunity. Our framework provides the ability to integrate multiple sources added or removed from the system.",
    // "content":"Inventory is crucial information/data which enables the store to carry out selling business. Stores are normally bound to one single status inventory system which restricts the business opportunity. Our framework provides the ability to integrate multiple sources added or removed from the system. The inventory maintenance is provided as a service."
  },
  {
    id: 5,
    image: Retail5,
    tittle: "CASHIER LESS AI/CAMERA ENABLED STORE",
    content:
      "This is a unique and ultimate customer experience in shopping where the customer does the complete shopping in a store without any cashier or salesperson interface. The framework uses AI and motion scanners to monitor customers in a seamless approach.",
  },
  {
    id: 6,
    image: Retail6,
    tittle: "PAYMENT AS A SERVICE",
    content:
      "We have built a unique generic framework for payment service where any 3rd party payment gateways are either pre-integrated or can be integrated through configuration.",
  },
  {
    id: 7,
    image: Retail7,
    tittle: "CLOUD BASED omnichannel LiDO POS",
    content:
      "LiDO POS is an omni channel enabled cloud-based POS which has features like a Centralised single Promotion engine across channels, RFID-enabled self-checkout, omnichannel returns and refunds, coupon as service.",
  },
  {
    id: 8,
    image: Retail8,
    tittle: "Printer as a Service",
    content:
      "Our framework provides printers activation or installation as a service in such a manner that removing or adding a printer is seamless to the store operation.",
  },
];
