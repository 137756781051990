import React, { useEffect, useRef } from 'react';

import '../styles/pages/service.css';
import {
  ConsumerTechpro,
  Diagram,
  oracleretail,
  service4,
  service5,
  serviceImg,
  services02,
} from '../assets';
import { Link, useParams } from 'react-router-dom';

const Services = () => {
  const moveToCard = (id) => {
    // if (month === 'orpos') {
    //   window.scrollTo(0, 700);
    // } else if (month === 'xstore') {
    //   window.scrollTo(0, 2200);
    // } else if (month === 'consumertech') {
    //   window.scrollTo(0, 2600);
    // }
    if (id === 'orpos') {
      if (orposRef.current) {
        orposRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (id === 'xstore') {
      if (xstoreRef.current) {
        xstoreRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (id === 'consumertech') {
      if (consumerTechRef.current) {
        consumerTechRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const { id } = useParams();
  const orposRef = useRef(null);
  const xstoreRef = useRef(null);
  const consumerTechRef = useRef(null);

  useEffect(() => {
    // if (id === "orpos") {
    //   if (orposRef.current) {
    //     orposRef.current.scrollIntoView({ behavior: "smooth" });
    //   }
    // }
    if (id === 'xstore') {
      if (xstoreRef.current) {
        xstoreRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (id === 'consumer') {
      if (consumerTechRef.current) {
        consumerTechRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [id]);
  return (
    <div id="service_main_top">
      {/* Banner  */}
      <section className="service_banner">
        <div className="shadow">
          <h1 className="service_banner_title">
            100+ years of Omnichannel{' '}
            <span style={{ color: '#E7BE0D', fontSize: 42 }}>
              <span style={{ fontWeight: 'bold', fontSize: 42 }}>S</span>
              ervices
            </span>
          </h1>
        </div>
      </section>

      {/* ShowCase Image */}
      <div className="serviceMainImg">
        <img src={serviceImg} alt="e2e services" srcset="" />
      </div>
      {/* Content */}
      <section className="services__heading">
        <div className="services__heading_data text-center">
          <span className="services__heading_intro">
            End-to-end services to provide roadmap, enhance quality,
            <br />
            optimize performance, and boost business development
          </span>

          <small className="services__heading_subinfo">
            Free yourself from business obstacles, technical complexity, and
            focus on driving your revenue. Let’s do what we do best.
          </small>
        </div>

        <div className="services__button_group ">
          <div className="btncontainer">
            {/* <Link to={'#orpos'}> */}
            <button className="xstorebtn" onClick={() => moveToCard('xstore')}>
              {/* <img className='service_oracleImg' src={xstorepro} alt="service_heading_img" srcset="" /> */}
              <img
                src={oracleretail}
                className="service_xstore_img"
                alt="service_heading_img"
                srcset=""
              />

              <span>XStore</span>
            </button>
            {/* </Link> */}
          </div>
          <div className="btncontainer">
            {/* <Link to={'#xstore'}> */}
            <button
              className="service_xstore_button_parent"
              onClick={() => moveToCard('orpos')}
            >
              <img
                src={oracleretail}
                className="service_xstore_img"
                alt="service_heading_img"
                srcset=""
              />
              <span
                className="service_xstore_button_content"
                // style={{ display: "flex" }}
              >
                <li className="boxydesignpro">C E</li>
                <li className="boxydesign">Customer engagement</li>
                {/* <div className='line'></div> */}
                <li className="boxydesignpro">O B</li>
                <li className="boxydesign">Order Broker</li>
                {/* <div className='line'></div> */}
                <li className="boxydesignpro">O M S</li>
                <li className="boxydesign">Order Management System</li>
              </span>
            </button>
            {/* </Link> */}
          </div>
          <div className="btncontainer">
            {/* <Link to={'#consumertech'}> */}
            <button
              className="service_consumer_tech_button_parent"
              onClick={() => moveToCard('consumertech')}
            >
              <img
                className="service_consumer_tech"
                src={ConsumerTechpro}
                alt="service_heading_img"
                srcset=""
              />
              <span className="service_consumer_tech_button_content">
                Consumer Tech
              </span>
            </button>
            {/* </Link> */}
          </div>
        </div>
      </section>

      <section className="service_main_container">
        <section className="service__orpos" id="orpos" ref={xstoreRef}>
          <div className="">
            <div className=" Oracle_mainSection1">
              <img
                className="service__product_img"
                src={services02}
                alt="main-show-case"
                srcset=""
              />
              <div className="Oracle_mainSection1_content">
                <strong className="service_heading_data">
                  Oracle - Xstore
                </strong>
                <p className="service_Subheading_data">
                  Suntech is the leading solution provider for oracle retail
                  Point-of-Service. Suntech global team has over 15 years of
                  experience working with leading global retailers. Our
                  expertise runs through the Xstore version 15/16 to latest
                  version 23/24.
                  <br />
                </p>
                <p className="service_Subheading_data">
                  Suntech Global extends its services in the area of solutioning
                  & designing, <br />
                  Roll-out & Maintenance support including Development &
                  Enhancement.{' '}
                </p>
                <p className="service_subheading1_data">
                  The Suntech Xstore team comprises of senior retail
                  Program/Project Managers/Solution Architects/Team Leads/Senior
                  Developer/ Developer /Testers & has ensured that the team
                  members are ORACLE certified.
                </p>
                <p className="service_subheading1_data">
                  WE OFFER SEAMLESS MIGRATION FROM ORPOS TO XSTORE!
                </p>
              </div>
            </div>
            <div className="service_main_data">
              <div>
                <p className="service_process_data">
                  Suntech Global has unique solution to migrate existing ORPOS
                  users to Xstore which mitigates risks and minimizes downtime
                  by utilizing a well-designed utility called Point-of-Sale
                  Conversion Utility (PCU) and configuration of the POS solution
                  offering seamless integration with other business modules,
                  efficient data migration, and automated testing for a
                  successful implementation with reduced human errors.
                </p>
                <ul className="bulletLine_service_Subheading1_data">
                  <li>
                    Suntech Global offers a specialized solution called the
                    Point-of-Sale Conversion Utility (PCU), a user-friendly and
                    intuitive tool, which simplifies the data migration and
                    conversion process, ensuring automated and efficient data
                    transfer during the store Roll-out.
                  </li>
                  <li>
                    With PCU, businesses can seamlessly move from ORPOS (V14 and
                    up) to Xstore (V16 and beyond) without losing data.
                  </li>
                </ul>
              </div>
            </div>

            <div className="service_main_data">
              <div className="diagram_container">
                <img
                  className="service__product_img_Diagram"
                  src={Diagram}
                  alt="Diagram"
                  srcset=""
                />
              </div>
              <p className="caseStudyIntro">
                Here we highlight the real-world success stories that
                demonstrate the power of our solutions and the transformative
                impact they've had on our clients' businesses.{' '}
              </p>
              <div className="caseStudyMain">
                <span className="Specialspan">
                  <p>Case Study 1 :</p>
                  <a
                    className="Explore_text"
                    href="https://drive.google.com/file/d/12HPrU_LZUOiuKdq-2QLW_JRfRHjyGSCT/view?usp=sharing"
                  >
                    A multinational retailing and consumer finance group,
                    positioned as a prominent global retailer...
                  </a>
                </span>
                <span>
                  <p>Case Study 2 :</p>

                  <a
                    className="Explore_text"
                    href="https://drive.google.com/file/d/15yo32qp_VGmbBCvu4JAF1sayR9UJuih6/view?usp=drive_link"
                  >
                    Saudi Arabia's premier multi-brand fashion store and a
                    dominant force in the fashion industry...
                  </a>
                </span>
                <span className="Specialspan">
                  <p>Case Study 3 :</p>

                  <a
                    className="Explore_text"
                    href="https://drive.google.com/file/d/1Zkq6OeXZQTwQLUiM55NkMDS7rlA4W63c/view?usp=sharing"
                  >
                    A prominent Privately-owned company in the Kingdom of Saudi
                    Arabia (KSA)...
                  </a>
                </span>
                <span>
                  <p>Case Study 4 :</p>

                  <a
                    className="Explore_text"
                    href="https://drive.google.com/file/d/1jJAqpYMr4AXIOXTVfSdYVJlKVsGWdOAv/view?usp=sharing"
                  >
                    A Large Global Retailer in Americas...
                  </a>
                </span>
              </div>
            </div>
          </div>
        </section>
        {/* <section className='otherSection' ref={orposRef}>
          <img className='otherSectionImg' src={service4} alt='' srcset='' />
          <div className='otherSectionContent'>
            <strong className='otherSectionHeading'>Oracle - ORPOS</strong>
            <p className='otherSectionContent_para'>
              Suntech offers deep customization and enhancement expertise on
              ORPOS acquired over a period of 18 years by supporting large
              global retailers in fashion, home improvement, pharma, consumer
              electronics and grocery space.
            </p>
            <div className='otherSectionSpan'>
              <p>Case Study 1 :</p>
              <a
                className='Explore_text'
                href='https://drive.google.com/file/d/12HPrU_LZUOiuKdq-2QLW_JRfRHjyGSCT/view?usp=sharing'
              >
                A renowned multi-brand fashion store in India, a pioneer in
                offering the latest trends....
              </a>
            </div>
          </div>
        </section> */}
        <section
          className="otherSection"
          id="consumertech"
          ref={consumerTechRef}
        >
          <img src={service5} className="otherSectionImg" alt="" srcset="" />
          <div className="otherSectionContent">
            <strong className="service_heading_data">Consumer - Tech</strong>
            <div style={{ display: 'flex' }}>
              <div className="boxydesign2">React</div>
              <div className="boxydesign2">Java/Micro-Services</div>
            </div>
            <p className="service_subheading1_data">
              The approach adopted by Suntech is consumer-centric, focusing on
              the optimization of operations and the empowerment of
              internet/ecommerce companies to deliver world-class customer
              experiences. Suntech provides solutions that are cheaper, faster,
              and superior in quality.
            </p>

            <ul className="bulletLine">
              <li className="">Consulting</li>
              <li className="">
                Startup Product Development using the latest digital/automation
                tools.
              </li>
              <li className="">Project Discovery & Analysis</li>
              <li className="">
                Bespoke processes for creating a living technical blueprint to
                drive product development.
              </li>
              <li className="">
                Design and Prototyping using newer technologies like React,
                Node, Java, Microservices.
              </li>
              <li className="">
                UI/UX Design for platforms and rapid cost-effective prototyping.
              </li>
              <li className="">
                Data-driven approach for turning the MVP into a fully-fledged
                digital product.
              </li>
            </ul>
          </div>
        </section>
      </section>

      {/* <Servicebanner2
        img={servicesbanner2}
        data={'The hard part we care, the challenges we share.'}
        smalldata={
          'Suntech  services are right here to assist your business success.'
        }
        btndata={'Schedule a Call with Us'}
      /> */}
      <section className="service_banner2">
        <div className="shadow">
          <h1 className="service_banner2_title">
            The hard part we care, the challenges we share.
          </h1>
          <small className="service_banner2_small">
            Suntech services are right here to assist your business success.
          </small>
          <button className="service_banner2_button">
            Schedule a Call with Us
          </button>
        </div>
      </section>
    </div>
  );
};

export default Services;
