import React, { useState, useEffect } from 'react';
import '../styles/components/header.css';
import { cLogo, cLogo2 } from '../assets';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const [mobileView, setMobileView] = useState(false);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const mobileShowCase = () => {
    setMobileView(!mobileView);
    // const header = document.querySelector('.header_main');
    // header.classList.toggle('active');
  };
  useEffect(() => {
    setMobileView(false);
  }, [location.pathname]);

  return (
    <div className="header_Container">
      <header className="header_main " id="header">
        <nav>
          <div className="header_nav_title">
            <a href="./">
              <img src={cLogo2} alt="" srcset="" className="header_logo" />
            </a>
          </div>

          <div>
            <ul id="nav-menu">
              <li onClick={goToTop} className="li_Of_About">
                <Link
                  to="#"
                  className={
                    location.pathname === '/about'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  About us <i class="fa fa-caret-down"></i>
                  {/* {location.pathname === '/about' ? (
                    <i class="fa fa-caret-down"></i>
                  ) : (
                    <i class="fa fa-caret-up"></i>
                  )} */}
                </Link>

                <div className="header_dropdown_menu">
                  <li>
                    <Link className="subLink" to={'/about'}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link className="subLink" to={'/about/vision'}>
                      Vision
                    </Link>
                  </li>
                  <li>
                    <Link className="subLink" to={'/about/mission'}>
                      Mission
                    </Link>
                  </li>
                  <li>
                    <Link className="subLink" to={'/about/culture'}>
                      Culture & Value
                    </Link>
                  </li>
                  <li>
                    <Link className="subLink" to={'/about/leadership'}>
                      Leadership
                    </Link>
                  </li>

                  <li>
                    <Link className="subLink" to={'/alliances'}>
                      Alliances
                    </Link>
                  </li>
                </div>
              </li>
              <li onClick={goToTop} className="li_Of_creative">
                <Link
                  to="/about"
                  className={
                    location.pathname === '#'
                      ? 'header_nav-link active '
                      : 'header_nav-link '
                  }
                >
                  <span className={'creative'}>
                    <span className="creative1Word">C</span>reative{` `}
                    <span className="creative1Word">D</span>isruption{' '}
                    <i class="fa fa-caret-down"></i>
                    {/* <i class="fa fa-caret-up"></i> */}
                  </span>
                </Link>
                <div className="header_dropdown_menu2">
                  <li>
                    <Link
                      to="/technicaldesk"
                      className={
                        location.pathname === '/retail'
                          ? 'header_nav-link active'
                          : 'header_nav-link'
                      }
                    >
                      Technical Desk
                    </Link>
                  </li>
                  <li>
                    <Link className="subLink" to={'/odyssey'}>
                      Suntech Odyssey
                    </Link>
                  </li>
                  <li>
                    <Link className="subLink" to={'/caseStudy'}>
                      Case Study
                    </Link>
                  </li>
                  <li>
                    <Link className="subLink" to={'/culturevalue'}>
                      Gallery
                    </Link>
                  </li>
                </div>
              </li>

              <li onClick={goToTop}>
                <Link
                  to="/retail"
                  className={
                    location.pathname === '/retail'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Retail Platforms
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/service"
                  className={
                    location.pathname === '/service'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Services
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/career"
                  className={
                    location.pathname === '/career'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Careers
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/contactus"
                  className={
                    location.pathname === '/contactus'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            <div className="header_hamburger" onClick={mobileShowCase}>
              {mobileView ? (
                <>
                  <span className="header_bar_close1"></span>
                  <span className="header_bar_close2"></span>
                </>
              ) : (
                <>
                  <span className="header_bar"></span>
                  <span className="header_bar"></span>
                  <span className="header_bar"></span>
                </>
              )}
            </div>
          </div>
        </nav>
        {/* Mobile View Navigation */}
        {mobileView ? (
          <div className="mobile_navigation">
            <ul className="mobile_navigation_ul">
              <li onClick={goToTop} className="li_Of_creative">
                <Link
                  to="/about"
                  className={
                    location.pathname === '#'
                      ? 'header_nav-link active '
                      : 'header_nav-link '
                  }
                >
                  <span>
                    <span className="creative">C</span>reative{` `}
                    <span className="creative">D</span>isruption{' '}
                  </span>
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/about"
                  className={
                    location.pathname === '#'
                      ? 'header_nav-link active '
                      : 'header_nav-link '
                  }
                >
                  <span>About Us</span>
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/retail"
                  className={
                    location.pathname === '/retail'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Retail Platforms
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/odyssey"
                  className={
                    location.pathname === '/odyssey'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Suntech Odyssey
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/service"
                  className={
                    location.pathname === '/service'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Services
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/career"
                  className={
                    location.pathname === '/career'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Careers
                </Link>
              </li>
              <li onClick={goToTop}>
                <Link
                  to="/contactus"
                  className={
                    location.pathname === '/contactus'
                      ? 'header_nav-link active'
                      : 'header_nav-link'
                  }
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          ''
        )}
      </header>
    </div>
  );
};

export default Header;
