import React from "react";
import "../styles/components/Banner.css";
import { Link } from "react-router-dom";
const Banner = ({ image, tittle, suntechtagline, navbartittle }) => {
  console.log(
    "Width: " + window.screen.width + ": Height: " + window.screen.height
  );
  return (
    <>
      <section>
        <div className='banner_main'>
          <img src={image} alt='' />
          <span>{tittle}</span>
        </div>
      </section>
    </>
  );
};
export default Banner;
