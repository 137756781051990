import React, { useEffect, useState } from 'react';
import '../styles/pages/JobApplication.css';
import Overview from '../components/Overview';
import Application from '../components/Application';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    window.scrollTo(0, 0);
    setActiveTab('tab2');
  };
  return (
    <>
      <div className="Tabs">
        <ul className="nav">
          <li
            className={activeTab === 'tab1' ? 'active' : ''}
            onClick={handleTab1}
          >
            OVERVIEW
          </li>
          <li
            className={activeTab === 'tab2' ? 'active' : ''}
            onClick={handleTab2}
          >
            APPLICATION
          </li>
        </ul>

        <div className="container-fluid outlet">
          {activeTab === 'tab1' ? (
            <Overview onClick={handleTab2} />
          ) : (
            <Application />
          )}
        </div>
      </div>
    </>
  );
};
export default Tabs;
