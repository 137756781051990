import React, { useRef, useState } from 'react';
import '../styles/components/suntechgallery.css';
import ImageGallary from './ImageGallary';
import VideoGallary from './VideoGallary';
import { FaPlay } from 'react-icons/fa';
import { Video3 } from '../assets/suntechvideos';

function SuntechGallery() {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    setActiveTab('tab2');
  };

  const vidRef = useRef(null);
  const [hide, setHide] = useState(false);

  const handlePlayVideo = () => {
    vidRef.current.play();
    setHide(true);
  };
  return (
    <>
      <section>
        {/* <div className="container">
                <div className="row galleryrow">
                    <div className={activeTab === "tab1" ? "col-lg-6 suntechgallery__main active" : "col-lg-6 suntechgallery__main"} onClick={handleTab1}>
                        <div className="suntechgallery_tab" >
                            <ul className="suntechgallery_current_status">
                                <li className="">Images</li>
                            </ul>
                        </div>
                        <div className={activeTab === "tab1" ?"pill active":"pill"}></div>
                    </div>
                    <div  className={activeTab ===  "tab2" ? "col-lg-6 suntechgallery__main active" : "col-lg-6 suntechgallery__main"} onClick={handleTab2}>
                        <div className="suntechgallery_tab" >
                            <ul disable className="suntechgallery_current_status">
                                <li className="">Videos</li>
                            </ul>
                        </div>
                        <div className={activeTab === "tab2" ?"pill active":"pill"}></div>
                    </div>
                </div>
            </div> */}
      </section>
      <section>
        <div className="container-fluid">
          <div className="row ">
            <div className="col ">
              {/* <div className="gallery_video">
                <video
                  className=""
                  controls
                  loop
                  ref={vidRef}
                  muted
                  frameborder="0"
                  style={{ width: '100%' }}
                >
                  <source src={Video3} />
                </video>
                {hide ? (
                  ''
                ) : (
                  <span
                    className="gallery_button_video_icon"
                    onClick={() => handlePlayVideo()}
                  >
                    <FaPlay className="gallery_icon_leaderShip" />
                  </span>
                )}
              </div> */}
              {activeTab === 'tab1' ? <ImageGallary /> : <VideoGallary />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SuntechGallery;
