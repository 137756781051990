/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import {
  casestudy,
  casestudy1,
  casestudy2,
  casestudy3,
  casestudy4,
  casestudy5,
} from '../assets';
import '../styles/pages/CaseStudy.css';
import Breadcrump from '../components/Breadcrump';
function CaseStudy() {
  return (
    <>
      <section class="hero">
        <div class="hero__image-wrapper">
          <img
            id="hero__image"
            src={casestudy}
            alt="Picsum placeholder image"
          />
        </div>
        <div class="hero__text">
          <h1>Case Study</h1>
        </div>
      </section>
      <Breadcrump page={'Case Study'} />
      <section class="cardContainer_main">
        <div class="casestudycardmain">
          <section class="casestudycard">
            <div class="card__image-wrapper">
              <img
                id="card__image"
                src={casestudy1}
                alt="Picsum placeholder image"
              />
            </div>
            <div class="card__text">
              <h1>Case Study 1</h1>
            </div>
          </section>
          <div class="testmain">
            <p>
              A multinational retailing and consumer finance group, positioned
              as a prominent global retailer...
              <a
                href="https://drive.google.com/file/d/12HPrU_LZUOiuKdq-2QLW_JRfRHjyGSCT/view?usp=sharing"
                target="_blank"
              >
                {' '}
                <span className="readmore">read more</span>{' '}
              </a>
            </p>
          </div>
        </div>
        <div class="casestudycardmain">
          <section class="casestudycard">
            <div class="card__image-wrapper">
              <img
                id="card__image"
                src={casestudy2}
                alt="Picsum placeholder image"
              />
            </div>
            <div class="card__text">
              <h1>Case Study 2</h1>
            </div>
          </section>
          <div class="testmain">
            <p>
              Saudi Arabia's premier multi-brand fashion store and a dominant
              force in the fashion industry...
              <a
                href="https://drive.google.com/file/d/15yo32qp_VGmbBCvu4JAF1sayR9UJuih6/view?usp=drive_link"
                target="_blank"
              >
                <span className="readmore">read more</span>
              </a>
            </p>
          </div>
        </div>
        <div class="casestudycardmain">
          <section class="casestudycard">
            <div class="card__image-wrapper">
              <img
                id="card__image"
                src={casestudy3}
                alt="Picsum placeholder image"
              />
            </div>
            <div class="card__text">
              <h1>Case Study 3</h1>
            </div>
          </section>
          <div class="testmain">
            <p>
              A prominent Privately-owned company in the Kingdom of Saudi Arabia
              (KSA)...
              <a
                href="https://drive.google.com/file/d/1Zkq6OeXZQTwQLUiM55NkMDS7rlA4W63c/view?usp=sharing"
                target="_blank"
              >
                <span className="readmore">read more</span>
              </a>
            </p>
          </div>
        </div>
        <div class="casestudycardmain">
          <section class="casestudycard">
            <div class="card__image-wrapper">
              <img
                id="card__image"
                src={casestudy4}
                alt="Picsum placeholder image"
              />
            </div>
            <div class="card__text">
              <h1>Case Study 4</h1>
            </div>
          </section>
          <div class="testmain">
            <p>
              A large Global Retailer in Americas...
              <a
                href="https://drive.google.com/file/d/1jJAqpYMr4AXIOXTVfSdYVJlKVsGWdOAv/view?usp=sharing"
                target="_blank"
              >
                <span className="readmore">read more</span>
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default CaseStudy;
