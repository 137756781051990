import { Routes, Route, HashRouter } from 'react-router-dom';
import Landing from './pages/Landing';
import Odyssey from './pages/Odyssey';
import About from './pages/About';
import Mission from './pages/Mission';
import Vision from './pages/Vision';
import Aliances from './pages/Aliances';
import Error from './pages/Error';
import CultureValues from './pages/CultureValues';
import ContactUs from './pages/ContactUs';
import Services from './pages/Services';
import JobApplication from './pages/JobApplication';
import Workinprogress from './pages/Workinprogress';
import Career from './pages/Career';
import RetailPlatforms from './pages/RetailPlatforms';
// import Leadership from './pages/Leadership';
import CompanyNote from './pages/CompanyNote';
import Header from './components/Header';
import Footer from './components/Footer';
import TechnicalDesk from './pages/TechnicalDesk';
import CaseStudy from './pages/CaseStudy';

function AppRoute() {
  return (
    <HashRouter history={History}>
      <Header />
      <Routes basename={process.env.PUBLIC_URL}>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/:id" element={<About />} />
        {/* SubSection of About */}
        <Route path="/vision" element={<Vision />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/culturevalue" element={<CultureValues />} />
        <Route path="/alliances" element={<Aliances />} />
        {/* <Route path="/leadership" element={<Leadership />} /> */}
        <Route path="/odyssey" element={<Odyssey />} />
        <Route path="/service" element={<Services />} />
        <Route path="/service/:id" element={<Services />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/retail" element={<RetailPlatforms />} />
        <Route path="/retail/:id" element={<RetailPlatforms />} />
        <Route path="/jobapplication" element={<JobApplication />} />
        <Route path="/technicaldesk" element={<TechnicalDesk />} />
        <Route path="/caseStudy" element={<CaseStudy />} />

        <Route path="/cnote" element={<CompanyNote />} />

        <Route path="/*" element={<Error />} />
        <Route path="/workin" element={<Workinprogress />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default AppRoute;
