import React from 'react'
import { errorImg } from '../assets'
import "../styles/pages/error.css"

const Error = () => {
  return (
    <div className='error_main'>
      <img src={errorImg} alt="" srcset="" /> 
    </div>
  )
}

export default Error
