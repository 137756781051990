import React, { useState } from "react";
import "../styles/components/overview.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Overview = (props) => {
  const JobRole = localStorage.getItem("jobrole");
  const jobs = [
    {
      job_title: "JAVA Developer",
      company: "Suntech Global.",
      location: "Remote",
      employment_type: "Full-time",
      salary: "$80,000 - $100,000",
      responsibilities: [
        "Develop high-quality software design and architecture",
        "Identify, prioritize, and execute tasks in the software development life cycle",
        "Write well-designed, efficient code",
        "Troubleshoot, debug, and upgrade existing software",
        "Collaborate with internal teams to fix and improve software",
        "Participate in code reviews",
      ],
      requirements: [
        "Proven experience as a Software Engineer or similar role",
        "Experience with software development methodologies and best practices",
        "Proficiency in one or more programming languages such as Java, Python, or C++",
        "Strong problem-solving skills",
        "Ability to work independently and in a team environment",
        "Excellent communication skills",
      ],
      preferred_qualifications: [
        "Bachelor's degree in Computer Science or related field",
        "Experience with cloud technologies (AWS, Azure, GCP)",
        "Familiarity with Agile/Scrum methodologies",
        "Experience with version control systems (e.g., Git)",
      ],
      benefits: [
        "Competitive salary and benefits package",
        "Remote work flexibility",
        "Opportunity for professional growth and advancement",
        "Dynamic and collaborative work environment",
      ],
    },
    {
      job_title: "React Developer",
      company: "Suntech Global.",
      location: "Remote",
      employment_type: "Full-time",
      salary: "$80,000 - $100,000",
      responsibilities: [
        "Develop high-quality software design and architecture",
        "Identify, prioritize, and execute tasks in the software development life cycle",
        "Write well-designed, efficient code",
        "Troubleshoot, debug, and upgrade existing software",
        "Collaborate with internal teams to fix and improve software",
        "Participate in code reviews",
      ],
      requirements: [
        "Proven experience as a Software Engineer or similar role",
        "Experience with software development methodologies and best practices",
        "Proficiency in one or more programming languages such as Java, Python, or C++",
        "Strong problem-solving skills",
        "Ability to work independently and in a team environment",
        "Excellent communication skills",
      ],
      preferred_qualifications: [
        "Bachelor's degree in Computer Science or related field",
        "Experience with cloud technologies (AWS, Azure, GCP)",
        "Familiarity with Agile/Scrum methodologies",
        "Experience with version control systems (e.g., Git)",
      ],
      benefits: [
        "Competitive salary and benefits package",
        "Remote work flexibility",
        "Opportunity for professional growth and advancement",
        "Dynamic and collaborative work environment",
      ],
    },
    {
      job_title: "Accounts Manager",
      company: "Suntech Global.",
      location: "Remote",
      employment_type: "Full-time",
      salary: "$80,000 - $100,000",
      responsibilities: [
        "Develop high-quality software design and architecture",
        "Identify, prioritize, and execute tasks in the software development life cycle",
        "Write well-designed, efficient code",
        "Troubleshoot, debug, and upgrade existing software",
        "Collaborate with internal teams to fix and improve software",
        "Participate in code reviews",
      ],
      requirements: [
        "Proven experience as a Software Engineer or similar role",
        "Experience with software development methodologies and best practices",
        "Proficiency in one or more programming languages such as Java, Python, or C++",
        "Strong problem-solving skills",
        "Ability to work independently and in a team environment",
        "Excellent communication skills",
      ],
      preferred_qualifications: [
        "Bachelor's degree in Computer Science or related field",
        "Experience with cloud technologies (AWS, Azure, GCP)",
        "Familiarity with Agile/Scrum methodologies",
        "Experience with version control systems (e.g., Git)",
      ],
      benefits: [
        "Competitive salary and benefits package",
        "Remote work flexibility",
        "Opportunity for professional growth and advancement",
        "Dynamic and collaborative work environment",
      ],
    },
    {
      job_title: "Software Engineer",
      company: "Suntech Global.",
      location: "Remote",
      employment_type: "Full-time",
      salary: "$80,000 - $100,000",
      responsibilities: [
        "Develop high-quality software design and architecture",
        "Identify, prioritize, and execute tasks in the software development life cycle",
        "Write well-designed, efficient code",
        "Troubleshoot, debug, and upgrade existing software",
        "Collaborate with internal teams to fix and improve software",
        "Participate in code reviews",
      ],
      requirements: [
        "Proven experience as a Software Engineer or similar role",
        "Experience with software development methodologies and best practices",
        "Proficiency in one or more programming languages such as Java, Python, or C++",
        "Strong problem-solving skills",
        "Ability to work independently and in a team environment",
        "Excellent communication skills",
      ],
      preferred_qualifications: [
        "Bachelor's degree in Computer Science or related field",
        "Experience with cloud technologies (AWS, Azure, GCP)",
        "Familiarity with Agile/Scrum methodologies",
        "Experience with version control systems (e.g., Git)",
      ],
      benefits: [
        "Competitive salary and benefits package",
        "Remote work flexibility",
        "Opportunity for professional growth and advancement",
        "Dynamic and collaborative work environment",
      ],
    },
    {
      job_title: "Asst Manager",
      company: "Suntech Global.",
      location: "Remote",
      employment_type: "Full-time",
      salary: "$80,000 - $100,000",
      responsibilities: [
        "Develop high-quality software design and architecture",
        "Identify, prioritize, and execute tasks in the software development life cycle",
        "Write well-designed, efficient code",
        "Troubleshoot, debug, and upgrade existing software",
        "Collaborate with internal teams to fix and improve software",
        "Participate in code reviews",
      ],
      requirements: [
        "Proven experience as a Software Engineer or similar role",
        "Experience with software development methodologies and best practices",
        "Proficiency in one or more programming languages such as Java, Python, or C++",
        "Strong problem-solving skills",
        "Ability to work independently and in a team environment",
        "Excellent communication skills",
      ],
      preferred_qualifications: [
        "Bachelor's degree in Computer Science or related field",
        "Experience with cloud technologies (AWS, Azure, GCP)",
        "Familiarity with Agile/Scrum methodologies",
        "Experience with version control systems (e.g., Git)",
      ],
      benefits: [
        "Competitive salary and benefits package",
        "Remote work flexibility",
        "Opportunity for professional growth and advancement",
        "Dynamic and collaborative work environment",
      ],
    },
    {
      job_title: "Microsoft Dynamics 365",
      company: "Suntech Global.",
      location: "Remote",
      employment_type: "Full time/Contractual.",
      salary: "$80,000 - $100,000",
      jobDescription:
        "We are seeking an experienced and dynamic Senior Technical Consultant with a minimum of 8 years of expertise in Microsoft Dynamics 365 Business Central (D365 BC) and various versions of Microsoft Dynamics Navision (NAV). The ideal candidate should have a proven track record of successful project delivery, with in-depth knowledge and hands-on experience",
      responsibilities: [
        "Technical Expertise: Demonstrate proficiency in working with Microsoft Dynamics NAV versions including NAV 2013 R2, 2015, 2016, 2017, 2018, and MS Dynamics 365 BC.",
        "Functional Knowledge: Possess comprehensive experience in Retail, Sales and purchase, Fixed Assets, Human Resource Management, Warehouse Management, and Finance.",
        "Project Delivery: Successfully deliver assignments on time, showcasing an energetic and analytical approach to problem-solving.",
        "Customizations and Integrations: Exhibit excellent skills in Migration, Customizations, Reporting, Integration, and other related aspects.",
        "Continuous Learning: Remain open to learning new technologies as per project requirements and stay updated on the latest developments in the field.",
      ],
      requirements: [
        "Application Tools: Microsoft Dynamics 365 Business Central, Microsoft Dynamics NAV 2018,2017, 2016, 2015, 2013 R2, 2009 SP1.",
        "Programming Languages: C, C++, C#, C/AL code, AL Code.",
        "Technology: MS Dynamics Navision, MS Dynamics 365 BC.",
        "Application Server: NAV, NAS.",
        "Operating System: MS Windows Series.",
        "RDBMS: MS SQL Server",
        "Web Technologies: HTML, .Net.",
        "Web Server: IIS, IAS ADS, FTP, etc.",
      ],
      preferred_qualifications: [
        "Bachelor's degree in Computer Science or related field",
        "Experience with cloud technologies (AWS, Azure, GCP)",
        "Familiarity with Agile/Scrum methodologies",
        "Experience with version control systems (e.g., Git)",
      ],
      benefits: [
        "Competitive salary and benefits package",
        "Remote work flexibility",
        "Opportunity for professional growth and advancement",
        "Dynamic and collaborative work environment",
      ],
    },
  ];
  console.log("JobRole", JobRole);

  const [copied, setCopied] = useState(false);
  function copyTask() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    toast("Link Copied");
  }

  const onButton = () => {
    props.onClick("tab2");
  };

  return (
    <div className='container overview_main'>
      <div className='description mt-4'>
        <span className='text_decoration_span'>Description</span>
        <button className='sharejob' onClick={copyTask}>
          Share this job <i className='fa fa-share-alt'></i>
          <ToastContainer />
        </button>
      </div>
      <br />
      <div className='Upertext'>
        {jobs
          .filter((job) => job.job_title === JobRole)
          .map((job, index) => (
            <div key={index} className='job-details'>
              <p className='paragraph'>
                Suntech Global leadership team has 100+ years of business,
                domain, and technology experience enabling it to deliver
                cutting-edge solutions to the retail and consumer tech
                industries, costeffectively and leveraging the innovative
                engagement models and digital expertise driving the business
                growth, enhancing customer experience.
              </p>
              <h2>{job.job_title}</h2>
              <p>Company: {job.company}</p>
              {/* <p>Location: {job.location}</p> */}
              <p>Employment Type: {job.employment_type}</p>
              {/* <p>Salary: {job.salary}</p> */}
              {job.jobDescription ? (
                <>
                  <h3>Job Description:</h3>
                  <p className='paragraph'>{job.jobDescription}</p>
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
              <h3>Responsibilities:</h3>
              <ul>
                {job.responsibilities.map((responsibility, index) => (
                  <li key={index}>{responsibility}</li>
                ))}
              </ul>
              <h3>Requirements:</h3>
              <ul>
                {job.requirements.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
              {/* <h3>Preferred Qualifications:</h3>
              <ul>
                {job.preferred_qualifications.map((qualification, index) => (
                  <li key={index}>{qualification}</li>
                ))}
              </ul> */}
              {/* <h3>Benefits:</h3>
              <ul>
                {job.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul> */}
              <div className='apllybutton'>
                <button className='Apllyjob' onClick={onButton}>
                  Apply for this job
                </button>
              </div>
            </div>
          ))}
        {jobs.filter((job) => job.job_title === JobRole).length === 0 && (
          <h3>No job found for the given role or the job has expired.</h3>
        )}
      </div>
    </div>
  );
};

export default Overview;
