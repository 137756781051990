import React, { useEffect, useState } from "react";
import "../styles/pages/contactus.css";
import { BiMapAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [coampanyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");

  const resetForm = () => {
    document.getElementById("fname").value = "";
    document.getElementById("lname").value = "";
    document.getElementById("contact_email").value = "";
    document.getElementById("message").value = "";
    document.getElementById("contact_phone").value = "";
    document.getElementById("cname").value = "";
    document.getElementById("gridCheck").checked = false;
  };
  const grettingText = {
    background: "black",
  };
  const submithandler = (event) => {
    event.preventDefault();
    const config = {
      SecureToken: "c7139d26-7745-46f1-994e-f5a523211962",
      To: ["pramod@suntechglobal.in", "manoj@suntechglobal.in"],
      Cc: ["neha.mithal@suntechglobal.in", "akshita.khanna@suntechglobal.in"],
      From: "marketing@suntechglobal.in",
      Subject: `Connect With ${firstName} ${lastName}`,
      Port: 2525, // Elastic Email uses port 2525
      Secure: true, // Use a secure connection (SSL/TLS)
      Body: `Hello Team Suntech,<br/> <br/>
              <strong style=${grettingText}>GREETINGS!!</strong> 
              <br/><br/> ${firstName} ${lastName} from ${coampanyName} is trying to connect.
              <br/><br/>
              Message:  ${message}
              <br/> <br/>
              Please reach out to them at: ${phone},   ${email}
              <br/><br/> 
              Thanks & Regards,<br/>
              Suntech Global
              `,
    };
    if (window.Email) {
      window.Email.send(config)
        .then((message) => {
          toast("Sent Successfully", message);
          resetForm();
        })
        .catch((error) => {
          toast("Failed to send email. Please try again later.");
        });
    } else {
      toast("Failed to Send");
    }
  };
  const navigation = useNavigate();
  const MoveToTop = () => {
    navigation("#contactus_top");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id='contactus_top'>
      {MoveToTop}
      <ToastContainer />
      <section className='contact_banner'>
        <div className='shadow'>
          <h1 className='contact_banner_title'>Contact Suntech Global</h1>
          {/* <small className="contact_banner_small">
            Use this form to request a callback and we will get back to you
            shortly.
          </small> */}
        </div>
      </section>

      <div className='container-fluid'>
        <div className='row contactus_section_1 '>
          <div className='col-lg-12 mt-5 mb-5 '>
            <form
              className='contactus_form_area'
              onSubmit={submithandler}
              autoComplete='false'
            >
              <div class='form-row'>
                <div class='form-group col-md-6'>
                  <label for='inputEmail4'>First name*</label>
                  <input
                    type='text'
                    class='form-control'
                    name='fname'
                    id='fname'
                    placeholder='First Name'
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div class='form-group col-md-6'>
                  <label for='inputPassword4'>Last name</label>
                  <input
                    type='text'
                    class='form-control'
                    name='lname'
                    id='lname'
                    placeholder='Last Name'
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div class='form-row'>
                <div class='form-group col-md-6'>
                  <label for='inputAddress'>Email*</label>
                  <input
                    type='email'
                    class='form-control'
                    name='email'
                    id='contact_email'
                    placeholder='Enter Your Email'
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div class='form-group col-md-6'>
                  <label for='inputAddress2'>Phone number*</label>
                  <input
                    type='tel'
                    inputMode='numeric'
                    class='form-control'
                    name='phone'
                    id='contact_phone'
                    placeholder='Enter Your Number'
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div class='form-row'>
                <div class='form-group col-md-6'>
                  <label for='inputAddress3'>Company name*</label>
                  <input
                    type='text'
                    class='form-control'
                    name='cname'
                    id='cname'
                    placeholder=''
                    required
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div class='form-group col-md-6'>
                  <label for='inputAddress5'>Message</label>
                  <textarea
                    type='text'
                    class='form-control'
                    name='message'
                    id='message'
                    placeholder=''
                    onChange={(e) => setMessage(e.target.value)}
                    rows={1}
                    cols={1}
                  />
                </div>
              </div>
              <div class='form-row'>
                <div class='form-group col-md-12'>
                  <div class='form-check form_check_'>
                    <div style={{ marginLeft: 25 }}>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='gridCheck'
                        required
                      />
                      <label class='form-check-label w-100' for='gridCheck'>
                        I agree to receive other communication from Suntech
                        Global.*
                      </label>
                    </div>
                    <label class='form-check-label mt-3 ' for='gridCheck'>
                      In order to provide you the content requested, we need to
                      store and process your personal data. By entering your
                      email address above, you agree toreceive information about
                      our products and services. From time to time,we{" "}
                      <span>
                        <Link
                          to={``}
                          style={{ color: "#000", fontWeight: "bold" }}
                        >
                          {" "}
                          Privacy Policy.
                        </Link>
                      </span>
                    </label>
                    <button type='submit' class='mt-3 mb-2 contact_sumbit_btn'>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <section className='contact_address'>
        <div className='contactus_section_data'>
          <div className='contactus_wrap_div'>
            <span className='contactus_wrap_div_title'>Suntech Global, HQ</span>
            <p>
              ATS BOUQUET, B 805, Noida-Greater Noida Expy, Block B, Sector 132,
              Noida, Uttar Pradesh 201304
            </p>
            <div className='map_icon_design '>
              <span className='map_icon_design_icon'>
                <BiMapAlt />
              </span>
              <a
                href='https://maps.google.com/maps?ll=28.510215,77.380791&z=12&t=m&hl=en&gl=IN&mapclient=embed&cid=16579636502544211509'
                target='_blank'
                rel='noreferrer'
              >
                <span className='contactus_btn_map'>VIEW IN GOOGLE MAPS</span>
              </a>
            </div>
          </div>
        </div>

        <div className='contactus_section_map'>
          <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56095.69848012849!2d77.30457334863279!3d28.510214700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce882fb000001%3A0xe616b38e6f3f5e35!2sSuntech%20Global!5e0!3m2!1sen!2sin!4v1685094514928!5m2!1sen!2sin'></iframe>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
