import React from 'react'
import "../styles/pages/companynote.css"
import { Alliancesbanner } from '../assets'

const CompanyNote = () => {
  return (
    <>
      <section>
          <div className="CompanyNote_container">
              <div className="">
                  <div className="">
                      <div className="main_container">
                          <div className="company_image">
                              <img src={Alliancesbanner} alt="" />

                          </div>
                          <div className="companyHeading">
                              <span>ComapnyName</span>

                          </div>
                      </div>

                  </div>
              </div>
          </div>
       </section>
      <section>
        <div className="data">
            <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>
            <br/><span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores quibusdam, recusandae aspernatur exercitationem sequi similique ea iusto illo alias voluptates, temporibus numquam possimus at? Molestias repellendus aspernatur voluptate quae ratione.</span>

        </div>
      </section>
    </>
  )
}

export default CompanyNote
