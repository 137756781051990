import React from "react";
import "../styles/pages/culturevalues.css";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Breadcrump from "../components/Breadcrump";
import SuntechGallery from "../components/SuntechGallery";
import { useNavigate } from "react-router-dom";
import { SuntechGallerybg, suntechgallary } from "../assets";
const CultureValues = () => {
  const navigation = useNavigate();
  const MoveToTop = () => {
    navigation("#culture_top");
  };

  return (
    <div id='culture_top'>
      {MoveToTop}
      <Banner
        image={SuntechGallerybg}
        tittle={"Suntech Gallery"}
        suntechtagline={"Our Tech-Driven Family"}
        navbartittle={"Suntech Gallery"}
      />
      <Breadcrump page={"Suntech Gallery"} />

      <SuntechGallery />
    </div>
  );
};

export default CultureValues;
