import React, { useState } from "react";
import "../styles/components/imagegallary.css";
import {
  ImgFirst,
  img1,
  img2,
  img3,
  img4,
  img5,
  img7,
  img8,
  img9,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
} from "../assets/suntechImages";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

const ImageGallary = () => {
  const imageArray = [
    ImgFirst,
    img1,
    img2,
    img3,
    img4,
    img5,
    img7,
    img8,
    img9,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img21,
    img22,

    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img58,
    img59,
    img60,
    img61,
    img63,
    img64,
    img65,
    img66,
    img67,
    img68,
    img69,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    // img55,
    // img56,
    // img57,
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const openCarousel = (i) => {
    setSelectedImage(i);
    setModalOpen(true);
  };

  const closeCarousel = () => {
    setModalOpen(false);
  };

  const handleclick = (val) => {
    if (val === "l") {
      if (selectedImage === 0) {
        setSelectedImage(imageArray.length - 1);
      } else {
        setSelectedImage(selectedImage - 1);
      }
    }
    if (val === "r") {
      if (selectedImage === imageArray.length - 1) {
        setSelectedImage(0);
      } else {
        setSelectedImage(selectedImage + 1);
      }
    }
  };

  const totalPages = Math.ceil(imageArray.length / 10);
  const startIndex = (currentPage - 1) * 10;
  const endIndex = Math.min(startIndex + 10, imageArray.length);
  const currentImages = imageArray.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="container imagegallary mt-5 mb-5">
        {currentImages.map((item, index) => {
          return (
            <div className="box" key={index}>
              <img
                src={item}
                alt=""
                onClick={() => openCarousel(startIndex + index)}
              />
            </div>
          );
        })}
      </div>
      {modalOpen && (
        <div className="imagegallery_carsol">
          <span className="close" onClick={() => closeCarousel()}>
            &times;
          </span>
          <span className="left__circle" onClick={() => handleclick("l")}>
            <IoIosArrowDropleftCircle />
          </span>
          <div className="imgBox">
            <img src={imageArray[selectedImage]} alt="" autoFocus />
          </div>
          <span className="right_circle" onClick={() => handleclick("r")}>
            <IoIosArrowDroprightCircle />
          </span>
        </div>
      )}
      <div className="pagination mb-5">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ◀️
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => goToPage(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          ▶️
        </button>
      </div>
    </>
  );
};

export default ImageGallary;
