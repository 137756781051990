import React, { useRef } from "react";
import {
  animateIcon1,
  animateIcon2,
  animateIcon3,
  animateIcon4,
  animateIcon5,
} from "../assets";
import "../styles/pages/career.css";
import { Link, useNavigate } from "react-router-dom";
import { img5 } from "../assets/suntechImages";
import Breadcrump from "../components/Breadcrump";

const Career = () => {
  const navigate = useNavigate();
  console.log(navigator);

  const jobProfile = (data) => {
    navigate("/jobapplication");
    localStorage.setItem("jobrole", data);
    goToTop();
  };

  // const func = (id) => {
  //   document.getElementById(id).style.height = '1316px';
  //   document.getElementById('showmorebtn').style.display = 'none';
  // };
  // const func2 = (id) => {
  //   document.getElementById(id).style.height = '450px';
  //   document.getElementById('showmorebtn').style.display = 'block';
  // };
  // const moveToCard=(jobLocation)=>{
  //   if(jobLocation==="job"){
  //   window.scrollTo(0,700)
  //   }
  // }
  const goToTop = () => {
    window.scrollTo(0, 0);
  };
  const joboffers = useRef(null);
  const moveToCard = (id) => {
    if (id === "joboffers") {
      if (joboffers.current) {
        joboffers.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <div id='careerss'>
      <section className='career_banner'>
        <div className='shadow'>
          <h1 className='career_banner_title'>Careers at Suntech Global</h1>
          <button
            className='career_banner_button'
            onClick={() => moveToCard("joboffers")}
          >
            See our current vacancies
          </button>
        </div>
      </section>
      <Breadcrump page={"Career"} />
      <section className='career_img_story'>
        <img src={img5} alt='' srcset='' />
        {/* comment by subodh */}
        {/* <div className='career_story_title_container'>
          <span className='career_story_title'>Our story</span>
          <span className='career_dot'>...</span>
        </div> */}
      </section>
      <section className='career_story_section'>
        <div className='career_story_area'>
          {/* add by subodh */}
          <span>Our story ...</span>
          <p id='paragraphheight' className='paragraphDetail'>
            Suntech Global has been in the making since 2010 and was finally
            founded by Manoj Mittal and Pramod Kapoor in 2020-21,Suntech
            propelled to become a dominant solution provider to the global
            retail industry. Manoj Mittal and Pramod Kapoor's leadership and
            deep industry knowledge were instrumental in Suntech Global's
            growth. The company's employee-centric approach fosters innovation,
            resulting in exceptional customer service. Their skilled team
            provided critical solutions to consumer tech, retail, and healthcare
            industries. Suntech Global's commitment to quality led to successful
            partnerships with global clients. Despite its growth, the company
            maintained employee-friendly policies and a culture of innovation
            and respect. Manoj and Pramod's dedication created a second home for
            employees and showcased the power of teamwork. Suntech Global's
            legacy serves as an inspiration to others, emphasizing the mantra:
            "Customer is king!"
          </p>
        </div>
      </section>
      <section className='container-fluid career_job_section' ref={joboffers}>
        <div className='row'>
          <div className='col-12 career_job_title'>
            <span>Current Job Openings</span>
          </div>
        </div>
        <div className='row career_job_area'>
          {/* <div className='col-md-3 career_job_card'>
            <p>Tech - Full time - hybrid</p>
            <p>JAVA Developer</p>
            <p>(SpringBoot)</p>
            <p>India</p>
            <button
              className='career_job_area_btn'
              onClick={() => {
                jobProfile("JAVA Developer");
              }}
            >
              Apply
            </button>
          </div> */}
          {/* <div className='col-md-3 career_job_card'>
            <p>Tech - Full time - hybrid</p>
            <p>React Developer</p>
            <p>(Node/React)</p>
            <p>India</p>
            <button
              className='career_job_area_btn'
              onClick={() => {
                jobProfile("React Developer");
              }}
            >
              Apply
            </button>
          </div> */}
          <div className='col-md-3 career_job_card'>
            <p>Tech - Full time - hybrid</p>
            <p>XStore Team Lead</p>
            <p>(XStore Team Lead)</p>
            <p>India</p>
            <button
              className='career_job_area_btn'
              onClick={() => {
                jobProfile("XStore Team Lead");
              }}
            >
              Apply
            </button>
          </div>
          <div className='col-md-3 career_job_card'>
            <p>Tech - Full time/Contractual.</p>
            <p>Software Developer</p>
            <p>(Microsoft Dynamics 365)</p>
            <p>India</p>
            <button
              className='career_job_area_btn'
              onClick={() => {
                jobProfile("Microsoft Dynamics 365");
              }}
            >
              Apply
            </button>
          </div>
          {/* <div className='col-md-3 career_job_card'>
            <p>Non-Tech - Full time - hybrid</p>
            <p>Accounts Manager</p>
            <p>(Finance)</p>
            <p>India</p>
            <button
              className='career_job_area_btn'
              onClick={() => {
                jobProfile("Accounts Manager");
              }}
            >
              Apply
            </button>
          </div> */}
          {/* <div className='col-md-3 career_job_card'>
            <p>Tech - Full time - hybrid</p>
            <p>Software Engineer</p>
            <p>(Fresher)</p>
            <p>India</p>
            <button
              className='career_job_area_btn'
              onClick={() => {
                jobProfile("Software Engineer");
              }}
            >
              Apply
            </button>
          </div> */}
          {/* <div className='col-md-3 career_job_card'>
            <p>Non-Tech - Full time - hybrid</p>
            <p>Asst Manager</p>
            <p>(Human Resources)</p>
            <p>India</p>
            <button
              className='career_job_area_btn'
              onClick={() => {
                jobProfile("Asst Manager");
              }}
            >
              Apply
            </button>
          </div> */}
        </div>
      </section>

      <section className='container-fluid career_team_value'>
        <div className='row'>
          <div className='col-12 career_team_value_title'>
            <p>
              One team driven by its <span>values.</span> <br />
            </p>
            <span style={{ fontSize: "1.2rem" }}>
              We lead with our values in everything we do, and we <br />
              are proudly defensive of the culture we have created.
            </span>
          </div>
          <div className='col-12 career_team_value_title_animation'>
            <div className='animation_card'>
              <div className='animation_card_left'>
                <img
                  src={animateIcon5}
                  className='rotateimg'
                  alt=''
                  srcset=''
                />
                <small>
                  We value resilience in
                  <br />
                  more ways than one
                </small>
              </div>
              <div className='animation_card_right'>&gt;</div>
            </div>
            <div className='animation_card'>
              <div className='animation_card_left'>
                <img src={animateIcon2} alt='' srcset='' />
                <small>
                  We are passionate about
                  <br />
                  the journey ahead
                </small>
              </div>
              <div className='animation_card_right'>&gt;</div>
            </div>
            <div className='animation_card'>
              <div className='animation_card_left'>
                <img src={animateIcon1} alt='' srcset='' />
                <small>
                  We collaborate to <br />
                  achieve the best ideas
                </small>
              </div>
              <div className='animation_card_right'>&gt;</div>
            </div>

            <div className='animation_card'>
              <div className='animation_card_left'>
                <img src={animateIcon4} alt='' srcset='' />
                <small>
                  We respect the people
                  <br />
                  around us
                </small>
              </div>
              <div className='animation_card_right'>&gt;</div>
            </div>
            <div className='animation_card'>
              <div className='animation_card_left'>
                <img src={animateIcon3} alt='' srcset='' />
                <small>
                  We act responsibly to
                  <br />
                  build the strongest
                  <br />
                  teams
                </small>
              </div>
              {/* <div className="animation_card_right">&gt;</div> */}
            </div>
          </div>
          <div className='col-12 career_team_value_bottom'>
            <span style={{ fontSize: "1.2rem" }}>
              You will be challenged professionally and supported personally. We
              have designed our
              <br />
              working practices to give everyone the time and space to manage
              their life and interests.
            </span>
          </div>
        </div>
      </section>
      <section className='career_banner2'>
        <div className='shadow'>
          <h1 className='career_banner_title'>Let’s connect on LinkedIn</h1>
          <small className='career_banner_small'>
            Keep up to date with our insights, product news and new employee
            intros.
          </small>
          <Link to={"/contactus"}>
            <button className='career_banner_button'>
              Connect with Suntech
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Career;
