import React, { useState } from 'react'
import '../styles/components/videogallary.css'
import { img1, img10, img11, img12, img13, img2, img3, img4, img5, img6, img7, img8, img9 } from '../assets/suntechImages'
import { Video1, Video2, Video3, Video4 } from '../assets/suntechvideos';

const VideoGallary = () => {
    const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openCarousel = (imageSrc) => {
    console.log("im in side this",imageSrc);
    setSelectedImage(imageSrc);
    setModalOpen(true);
    console.log(modalOpen)
  };

  const closeCarousel = () => {
    setModalOpen(false);
  };
  return (
    <><div className="container VideoGallary mt-5 mb-5">
          <div className="box">
            <video className='' controls autoPlay loop muted frameborder="0">
              <source src={Video1}/>
            </video>
          </div>
          <div className="box">
            <video className='' controls autoPlay loop muted frameborder="0">
              <source src={Video2}/>
            </video>
          </div>
          
          
          <div className="box">
            <video className='' controls autoPlay loop muted frameborder="0">
              <source src={Video4}/>
            </video>
          </div>
          <div className="box">
            <video className='' controls autoPlay loop muted frameborder="0">
              <source src={Video3}/>
            </video>
          </div>
          <div className="box">
            <video className='' controls autoPlay loop muted frameborder="0">
              <source src={Video3}/>
            </video>
          </div>
          <div className="box">
            <video className='' controls autoPlay loop muted frameborder="0">
              <source src={Video3}/>
            </video>
          </div>

      </div>
      {modalOpen ?(
        <div className="modal_test">
          <span className="close" onClick={()=>closeCarousel()}>&times;</span>
          <img src={selectedImage} alt="" />
        </div>
      ):""}</>
  )
}

export default VideoGallary
