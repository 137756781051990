import React, { useEffect, useRef } from "react";
import "../styles/pages/about.css";
import { GrLinkedin } from "react-icons/gr";
import Banner from "../components/Banner";
import {
  BG,
  BtDot,
  GroupPhoto,
  companyTower,
  manoj_vid,
  pramod_vid,
  Employee1,
  Pposter,
  Mposter,
} from "../assets";

import Breadcrump from "../components/Breadcrump";
import { useNavigate, useParams } from "react-router-dom";
import { img54, img55, img56 } from "../assets/suntechImages";

const About = () => {
  const navigation = useNavigate();
  const MoveToTop = () => {
    navigation("#about");
  };

  useEffect(() => {
    let videoPause = document.getElementById("manojvideo");
    let videoPause2 = document.getElementById("pramodvideo");
    if (videoPause !== null) {
      document.addEventListener("scroll", () => {
        if (window.scrollY > 800 || window.scrollY < 100) {
          videoPause.pause();
        }
      });
      document.addEventListener("scroll", () => {
        if (window.scrollY > 1400 || window.scrollX < 500) {
          videoPause2.pause();
        }
      });
    }
  });

  const vidRef = useRef(null);
  const vidRef2 = useRef(null);

  const sectionRef = useRef(null);
  const leaderRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    if (id === "vision" || id === "mission" || id === "culture") {
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (id === "leadership") {
      if (leaderRef.current) {
        leaderRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  });

  return (
    <div id='about'>
      {MoveToTop}
      <Banner
        image={img54}
        tittle={"About Suntech Global"}
        // suntechtagline={"Inspiring Customers Worldwide through Digital Realization"}
        // navbartittle={"About Us"}
      />
      <Breadcrump page={"About"} />

      <section>
        <div className='container'>
          <div className='row about_main'>
            <div className='col-lg-6 col-md-12  col-xs-12 imageAbout'>
              <img src={GroupPhoto} alt='' />
            </div>
            <div className='col-lg-6 col-md-12  col-xs-12 about_section_1'>
              <p className='sections_1_data'>
                <span className='about_high_data'>Suntech Global</span>{" "}
                leadership team has 100+ years of business, domain and
                technology experience enabling it to deliver cutting edge
                solutions to the retail and consumer tech industries, cost
                effectively and leveraging the innovative engagement models and
                digital expertise driving the business growth through{" "}
                <span className='about_high_data'>
                  “Enhancing Customer Experience.”
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section ref={sectionRef}>
        <div className='container-fluid mt-5'>
          <div className='row MVC_Container'>
            <div className='col-lg-4 col-md-4 col-xs-12 '>
              <div className='Mvc_Main'>
                <div className='overlap-group'>
                  <div className='main-article-section'>01.</div>
                  <div className='text-wrapper'>
                    OUR
                    <br />
                    MISSION
                  </div>
                </div>
                <span>
                  <strong> Our mission</strong> is to assist customers monetize
                  digital investments in measurable ways and unlock growth
                  opportunities.
                </span>
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-xs-12'>
              <div className='Mvc_Main'>
                <div className='overlap-group'>
                  <div className='main-article-section'>02.</div>
                  <div className='text-wrapper'>
                    OUR
                    <br />
                    VISION
                  </div>
                </div>
                <span>
                  <strong>The Company</strong> aims to provide innovative and
                  cost effective Business solutions to the retail and Consumer -
                  Tech industry.
                </span>
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-xs-12'>
              <div className='Mvc_Main'>
                <div className='overlap-group'>
                  <div className='main-article-section'>03.</div>
                  <div className='text-wrapper'>
                    CULTURE &
                    <br />
                    VALUES
                  </div>
                </div>
                <span>
                  <strong>Our unyielding</strong> dedication to our customers,
                  partners, and employees is reflected in the core values that
                  underpin everything we do at Suntech.
                </span>
              </div>
            </div>
            <div className='about-us-bg-png'>
              <img src={BtDot} alt='' />
            </div>
            <div className='about-page-bg-png'>
              <img src={BG} alt='' />
            </div>
          </div>
        </div>
      </section>

      <section ref={leaderRef}>
        <div className='container mt-5 mb-5'>
          <div className='row OurLeaderMain'>
            <span>
              <span style={{ fontWeight: 600, marginRight: 10 }}>
                Our Leaders{" "}
              </span>{" "}
              Say It Best
            </span>
            <div className='col-lg-6 col-md-6 col-xs-6 leader'>
              <video
                poster={Mposter}
                ref={vidRef}
                className='leadership_leftside_video'
                id='manojvideo'
                controls
                frameborder='0'
              >
                <source src={manoj_vid} />
              </video>
              <div className='LedderInfo mt-2'>
                <a
                  className='linkdin_icon'
                  href='https://www.linkedin.com/in/manoj-mittal-238153100/'
                  target='_target'
                >
                  <GrLinkedin className='linkdin_icon_main' height={50} />
                </a>
                <div className='LeaderDetails'>
                  <strong>Manoj Mittal</strong>
                  <small>Cofounder & CEO</small>
                </div>
              </div>
              <p className='LeaderExp'>
                “30 Years of successful global experience, responsible for
                driving company’s business strategies.”
              </p>
            </div>
            <div className='col-lg-6 col-md-6 col-xs-6 leader'>
              <video
                poster={Pposter}
                ref={vidRef2}
                className='leadership_leftside_video'
                id='pramodvideo'
                controls
                muted
                frameborder='0'
              >
                <source src={pramod_vid} />
              </video>
              <div className='LedderInfo mt-2'>
                <a
                  className='linkdin_icon'
                  href='https://www.linkedin.com/in/pramod-kumar-kapoor-2453214/'
                  target='_target'
                >
                  <GrLinkedin className='linkdin_icon_main' />
                </a>
                <div className='LeaderDetails'>
                  <strong>Pramod Kapoor</strong>
                  <small>Cofounder & President</small>
                </div>
              </div>
              <p className='LeaderExp'>
                “30+ Years of Delivering complex engagements to large globally
                clients, responsible for solutioning and delivering all aspects
                of customer satisfaction.”
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container mt-5 mb-5">
          <div className="row OurTeamMain">
            <strong className="OurTeamHeading">Our Team</strong>
            <div className="col-lg-3 col-md-3 col-xs-3 OurTeam">
              <div className="TeamDetails">
                <img src={Employee1} alt="Ravi Kumar" />
                <strong>subodh</strong>
                <small>FrontEnd Developer</small>
                <div class="shadowMain">
                  <p className="text-h1">
                    Working here feels like being part of a supportive and
                    innovative family, where everyone's contributions are valued
                    and encouraged."
                  </p>
                  <strong className="text-p">Divyani Raghav</strong>
                  <span className="link-a">Group Account Manager</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-xs-3 OurTeam">
              <div className="TeamDetails">
                <img src={Employee1} alt="Ravi Kumar" />
                <strong>subodh</strong>
                <small>FrontEnd Developer</small>
                <div class="shadowMain">
                  <p className="text-h1">
                    Working here feels like being part of a supportive and
                    innovative family, where everyone's contributions are valued
                    and encouraged."
                  </p>
                  <strong className="text-p">Divyani Raghav</strong>
                  <span className="link-a">Group Account Manager</span>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-xs-3 OurTeam">
              <div className="TeamDetails">
                <img src={Employee1} alt="Ravi Kumar" />
                <strong>subodh</strong>
                <small>FrontEnd Developer</small>
                <div class="shadowMain">
                  <p className="text-h1">
                    Working here feels like being part of a supportive and
                    innovative family, where everyone's contributions are valued
                    and encouraged."
                  </p>
                  <strong className="text-p">Divyani Raghav</strong>
                  <span className="link-a">Group Account Manager</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-xs-3 OurTeam">
              <div className="TeamDetails">
                <img src={Employee1} alt="Ravi Kumar" />
                <strong>subodh</strong>
                <small>FrontEnd Developer</small>
                <div class="shadowMain">
                  <p className="text-h1">
                    Working here feels like being part of a supportive and
                    innovative family, where everyone's contributions are valued
                    and encouraged."
                  </p>
                  <strong className="text-p">Divyani Raghav</strong>
                  <span className="link-a">Group Account Manager</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default About;
