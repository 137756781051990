import React from 'react';
import '../styles/components/breadcrump.css';
const Breadcrump = ({ page }) => {
  return (
    <div className="breadcrump_main">
      <nav>
        <ul className="breadcrumb_ul">
          <li className="breadcrumb_items">
            <a href="./">
              Home <span>&gt;</span>
            </a>
          </li>
          <li className="breadcrumb_items active">{page ? page : ''}</li>
        </ul>
      </nav>
    </div>
  );
};
export default Breadcrump;
