import React from "react";
import "../styles/components/footer.css";
import { cLogo } from "../assets";
import { BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  const goToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className='footer_container'>
        <section className='footer_section1'>
          <section className='footer_section1_left'>
            <img className='footer_logo' src={cLogo} alt='' srcset='' />
            <span className='footer_discription'>
              Customer-centricity meets employee bliss, the perfect harmony we
              strive for.
            </span>
          </section>
          <section className='footer_section1_right'>
            <ul className='footer_section1_right_ul'>
              <span>About Suntech</span>
              <li>
                <Link className='link' to='/about' onClick={goToTop}>
                  About
                </Link>
              </li>
              <li>
                <Link className='link' to='/culturevalue' onClick={goToTop}>
                  Culture & Values
                </Link>
              </li>
              <li>
                <Link className='link' to='/alliances' onClick={goToTop}>
                  Alliances
                </Link>
              </li>
            </ul>
            <ul className='footer_section1_right_ul'>
              <span>Platforms</span>
              <li>
                <Link
                  className='link'
                  to='/retail/lidoselfchekout'
                  onClick={goToTop}
                >
                  LiDO Self Checkout
                </Link>
              </li>
              <li>
                <Link
                  className='link'
                  to='/retail/lidosmartstore'
                  onClick={goToTop}
                >
                  LiDO Smart Store
                </Link>
              </li>
              <li>
                <Link className='link' to='/retail/lidompos' onClick={goToTop}>
                  LiDO mPOS
                </Link>
              </li>
            </ul>
            <ul className='footer_section1_right_ul'>
              <span>Services</span>
              <li>
                <Link className='link' to='/service/xstore'>
                  ORACLE Xstore
                </Link>
              </li>
              <li>
                <Link className='link' to='/service/orpos'>
                  Retail ORPOS
                </Link>
              </li>
              <li>
                <Link className='link' to='/service/consumer'>
                  Consumer-Tech
                </Link>
              </li>
            </ul>
          </section>
        </section>
        <div className='footer_arrow'>
          <span className='footer_arrow_line'>{` `}</span>
          <span className='footer_arrow_newArrow' onClick={goToTop}>
            {" "}
            &#8593;
          </span>{" "}
        </div>
        <marquee className='marquee'>
          {/* The logos are respective trademarks of the companies shown in the
          website and serve as a testament to the quality of solutions Suntech
          Global provides and the valued collaborations. */}
          All logos, names and brands are of their respective companies.
        </marquee>
        <div className='footer_bottom_link_list'>
          <Link
            to='https://www.linkedin.com/company/suntechglobal/mycompany/'
            target='blank'
            className='footer_Logo_link'
          >
            <BsLinkedin size={40} />
          </Link>
          <Link
            to='https://www.youtube.com/@SuntechGlobal-Infos805'
            target='blank'
            className='footer_Logo_link'
          >
            <BsYoutube size={50} />
          </Link>
        </div>
      </div>
      <section className='footer_bottom_link'>
        <div className='footer_copyright'>
          <span>© Copyright 2021 Suntech Global </span>|{" "}
          <span className='footer_copyright_cool'>Terms & Conditions</span> |{" "}
          <span className='footer_copyright_cool'>Privacy Policy</span>
        </div>
      </section>
    </>
  );
};

export default Footer;
