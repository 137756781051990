import React from 'react'
import "../styles/pages/vision.css"
import Banner from '../components/Banner'
import {  Card2, mission1,  mission22,  visionbanner} from '../assets'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SmallCards from '../components/SmallCards'
import Breadcrump from '../components/Breadcrump'
import { useNavigate } from 'react-router-dom'


const Vision = () => {
  const navigation = useNavigate()
    const MoveToTop =()=>{
        navigation("#vision")

    }
  return (

        <div div id='vision'>
          {MoveToTop}
        <Header/>
        <Banner image={visionbanner}tittle={"Our Vision"}  suntechtagline={"Empowering Minds, igniting change"} navbartittle={"Vision"}/>
        <Breadcrump page={"Our Vision"}/>
        <div className="container-fluid  mb-2 vision_main">
          <div className="row vision_section_1_row">
              <div className="col-12 vision_section_1">
                  <p>
                    <span>The Company</span> aims to provide innovative and cost effective Business solutions to the retail and
                    Consumer-Tech industry.
                  </p>
              </div>
          </div>
      </div>
      <SmallCards image1={Card2}tittle1={"Mission"}paragraph1={"Our mission is to assist customers monetize digital investments in measurable ways and unlock growth opportunities."}link1={"/mission"}
      image2={mission22}tittle2={"Culture & Values"}paragraph2={"Our unyielding dedication to our customers, partners, and employees is reflected in the core values that underpin everything we do at Suntech"}link2={"/culturevalue"}/>
      <Footer id={"vision"}/>
      </div>

  )
}

export default Vision
