import React from 'react'
import "../styles/pages/mission.css"
import Banner from '../components/Banner'
import {  Card1, mission1, mission2, mission22, missionbanner } from '../assets'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SmallCards from '../components/SmallCards'
import Breadcrump from '../components/Breadcrump'
import { useNavigate } from 'react-router-dom'

const Mission = () => {
  const navigation = useNavigate()
    const MoveToTop =()=>{
        navigation("#mission_top")

    }
  return (

        <div id='mission_top'>
          {MoveToTop}
        <Header/>
        <Banner image={missionbanner}tittle={"Our Mission"} suntechtagline={"Driving Digital Transformation, Inspiring Tomorrow"} navbartittle={"Mission"}/>
        <Breadcrump page={"Our Mission"}/>
        <div className="container-fluid mt-5 mb-2 mission_main">

          <div className="row">
              <div className="col-12 mission_section_1">
                  <ul>
                    <li className='mission_sub_heading'>
                        <span>Our mission</span> is to assist customers monetize digital investments in measurable
                        ways and unlock growth opportunities.
                    </li>
                    <li className='mission_sub_heading'>
                        <span>We make</span> it easier for the customers to use powerful retail solutions everyday
                        that helps them achive their business goals and exceed results.
                    </li>
                  </ul>
              </div>
          </div>
      </div>
      <SmallCards image1={Card1}tittle1={"Vision"}paragraph1={"The Company aims to provide innovative and cost effective Business solutions to the retail and Consumer-Tech industry."}link1={"/vision"}
      image2={mission22}tittle2={"Culture & Values"}paragraph2={"Our unyielding dedication to our customers, partners, and employees is reflected in the core values that underpin everything we do at Suntech"}link2={"/culturevalue"}/>
      <Footer id={"mission_top"}/>
      </div>

  )
}

export default Mission
