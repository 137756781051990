import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../styles/components/application.css";
import { india } from "../assets";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Application = () => {


  const [firstName,setFirstName] = useState("")
  const [lastName,setLastName] = useState("")
  const [phone, setPhone] = useState("");
  const [email,setEmail] = useState("")
  const [address,setAddress] = useState("")
  const [summmery,setSummmery] = useState("")
  const [notice,setNotice] = useState("")
  const [message,setMessage] = useState("")
  const [pdfile,setPDF]=useState('');
  let jobrole=localStorage.getItem("jobrole");
  let allFilesConverted=[];
  // console.log(pdfile)

  function convertFile(){
      var reader;
      var dataUri;
      var file = document.getElementById("pdffile").files[0];
      console.log("file",file);
      reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function () {
        dataUri = "data:" + file.type + ";base64," + btoa(reader.result);
      }
      reader.onloadend= function(){
        allFilesConverted.push({"name" : file.name, "data" : dataUri});
      }
  };


  // console.log("sss",)



  const resetForm = () => {
    document.getElementById("fname").value=""
    document.getElementById("lname").value=""
    document.getElementById("contact_email").value=""
    document.getElementById("contact_phone").value=""
    document.getElementById("address").value=""
    document.getElementById("pdffile").value=""
  }
  const submithandler =(event)=>{
    event.preventDefault()
    console.log("SS",allFilesConverted);
    do{
        convertFile()
        console.log(allFilesConverted.length);
      }while(allFilesConverted.length ==="1")

      const config={
        SecureToken : "74560eb5-171a-4ad8-9736-21d69da7443a",
        To : ["hrd@suntechglobal.in","pramod@suntechglobal.in"],
        // To:"subodh.singh@suntechglobal.in",
        Cc : "akshita.khanna@suntechglobal.in",
        From : "suntechglobalinfosolutions@gmail.com",
        Subject : `${firstName} ${lastName} has applied for ${jobrole}`,
        Body : `
        Hi Team Suntech! <br/><br/>
        You have a new application for <strong style="color:black;font-style:italic;" >${jobrole}</strong><br/><br/>
        Applicant Name: ${firstName} ${lastName} <br/>
        Email: ${email} <br/> 
        Phone Number: ${phone}<br/> 
        Summary: ${summmery}<br/> 
        Address: ${address}<br/><br/>
        <strong style="color:black" >Thanks & Regards</strong>,<br/>
        Suntech Global Careers`,
        Attachments :allFilesConverted
      }
      if(window.Email){
        window.Email.send(config).then((message)=> toast("Applied Successfully"))
        resetForm()
      }
  }
  

  return (
    <div className="container application_main">
      <p>*Required fields</p>
      <div className="personal_Info_heading">
        <span className="personal_info_text">Personal information</span>
        <p className="mb-0" style={{cursor:'pointer'}} onClick={resetForm}>
          <i class="fa fa-trash" aria-hidden="true" ></i>&nbsp; Clear
        </p>
      </div>
      <ToastContainer />
      <hr className="mt-4" width="100%" color="black" />

      <form name="myForm" onSubmit={submithandler} enctype="multipart/form-data" method="post">
        <div className="name">
          <div className="fname">
            <label>*First name</label>
            <br />
            <input
              className="text_box"
              type="text"
              id="fname"
              name="firstname"
              required onChange={(e)=>setFirstName(e.target.value)}
            />
          </div>

          <div className="lname">
            <label>Last name</label>
            <br />
            <input
              className="text_box"
              type="text"
              id="lname"
              name="lastname"
              onChange={(e)=>setLastName(e.target.value)}
            />
          </div>

        </div>

        <br />

        <div className="text_field">

          <label>*Email</label>
          <br />
          <input
            className="text_box_email"
            type="email"
            id="contact_email"
            name="email"
            required
            onChange={(e)=>setEmail(e.target.value)}
          />

          <br />
          <br />

          <label className="Phone">*Phone</label>
          <br />

          <div className="InputNumber">
            <img src={india} alt="" />
            <input
              className="input_area"
              type="tel"
              onKeyDown={(evt) => ["e", "." ,"ArrowUp","ArrowDown", "E", "+", "-"].includes(evt.key) &&               
              evt.preventDefault()}
              onChange={(e)=>setPhone(e.target.value)}
              placeholder="Enter Phone Number"
              autoComplete="off"
              // value={phone}
              id="contact_phone"
            />
          </div>

          <br />
          <label>Address</label>
          <br />
          <input
            className="text_box_email"
            type="text"
            id="address"
            name="address"
            required
            onChange={(e)=>setAddress(e.target.value)}
          />
        </div>

        <br />
        <br />
        <br />

        <div className="personal_Info_heading">
          <span className="personal_info_text">Profile</span>
          {/* <p className="mb-0">
            <i class="fa fa-trash" aria-hidden="true"></i>&nbsp; Clear
          </p> */}
        </div>
        <hr className="mt-4" width="100%" color="black" />
        <br />

        <div className="Edu_Exp_main">
          <div className="education">
            <p>
              <span>Education</span>&nbsp; &nbsp;(Optional)
            </p>
            <button className="button_add">+Add</button>
          </div>
          <br />
          <div className="education">
            <p>
              <span>Experience</span>&nbsp; &nbsp;(Optional)
            </p>
            <button className="button_add">+Add</button>
          </div>
          <br />
          <p><span>Summary</span>&nbsp; &nbsp;(Optional)</p>
          <textarea
            className="text_box_summary"
            type="textarea"
            id="summery"
            name="summery"
            required onChange={(e)=>setSummmery(e.target.value)}
          />
          <br />
          <br />

            <div class="form-group files">
              <label>
                Resume <i class="fa fa-question-circle" aria-hidden="true"></i>{" "}
              </label>
              <input type='file' id="pdffile" 
              class=" resume_file resume_text" 
              accept="image/*,video/*,.pdf,.txt,.html,.js,.css,.odt,.odp,.odf,.docx" 
              name='fichiersSupp'  
              multiple
              onChange={convertFile}
              required
              />
            </div>
          <br />
          <br />

          {/* <div className="personal_Info_heading">
            <span className="personal_info_text">Details</span>
            <p className="mb-0">
              <i class="fa fa-trash" aria-hidden="true"></i>&nbsp; Clear
            </p>
          </div>
          <hr className="mt-4" width="100%" color="black" />
          <br />
          <br /> */}

          {/* <span>* What is your notice period/earliest availability?</span>
          <br /> */}
          {/* <input
            type="text"
            className="text_box_email mt-3"
            id="noticePeriod"
            name="notice"
            required
            onChange={(e)=>setNotice(e.target.value)}

          /> */}
          <br />
          <br />
          <div className="apllybutton mt-4">
              <button type="submit" className="Apllyjob">Apply for this job</button>
          </div>
        </div>
      </form>

    </div>
  );
};

export default Application;
